import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  AGENT_PORTAL_URL,
  AGENT_SERVER_URL,
  LOGO,
  APP_URL,
} from "../../../config/config";
import qbImage from "../../../assets/images/quickBook.png";
import qbIcon from "../../../assets/images/qb_icon.png";
import { formatAsCurrency } from "../../../config/utility";
import {
  getSingleRfq,
  deleteAnswerOption,
  deleteQuestions,
  listServicesWithPrice,
  getSingleSalesOrder,
  listSingleClient,
  saveQuotationAsDraft,
  saveQuotationAndSent,
  saveQuotationInformation,
  getPaymentMethod,
  getPaymentLogs,
  markOrderAsPaid,
  getOrderCommission,
  getOrderComments,
  sendOrderEmail,
  rejectSalesOrder,
  saveQuotationComment,
  getOrderDocuments,
  deleteOrderDocument,
  createExternalInvoice,
  approveCommission,
  getOrderPaidCommission
} from "./../../../config/reseller_api_calls";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { InvoiceLoader } from "../../../components/ContentLoaders/newLoaders/InvoiceLoader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import DataTable from "../../../components/Tables/DataTable";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import { Editor } from "@tinymce/tinymce-react";
import localization from "../../../localization/localization";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const animatedComponents = makeAnimated();

const auth = new HelperClass();
const newHeight = window.screen.height;

class EditUotations extends Component {
  constructor() {
    super();
    const numberOfStates = 100;

    // Initialize state object with incremented values
    const initialState = {};
    const initialStateOptional = {};
    for (let i = 1; i <= numberOfStates; i++) {
      initialState[`select_a_service_${i}`] = null; // You can set any initial value as needed
      initialStateOptional[`select_a_service__optional_${i}`] = null;
    }
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.state = {
      pageLoader: true,
      tableData: [],
      errorMessage: "",
      formLoader: true,
      successMessage: "",
      convErrorMessage: "",
      download_start: false,
      tableLoader: true,
      copied: false,
      copied2: false,
      disabled: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      quote_comment_button: false,
      tableLoader: true,
      paymentMethodLoader: true,
      payErrorMessage: "",
      paySuccessMessage: "",
      paymentLogLoader: true,
      payLogErrorMessage: "",
      payment_logs: [],
      order_comments: [],
      orderCommentLoader: true,
      selected_options: [],
      rfq_requirements: "",
      rfq_attachment: "",
      client_accountno: "",
      rfq_accountno: "",
      payment_status: "",
      payment_receipt: "",
      payment_notes: "",
      payment_date: new Date(),
      payment_method_pay: "Quickbooks",
      payment_button: false,
      pay_amount: "",
      amountExceedError: "",
      payment_process: "paid",
      draft_button: false,
      send_client_button: false,
      quotation_services: [],
      rfq_attachement: "",
      questions: [],
      all_answers: [],
      client_details: [],
      client_name: "",
      client_company: "",
      client_email: "",
      client_phone: "",
      client_address: "",
      client_address2: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_country: "",
      reseller_name: "",
      reseller_company: "",
      reseller_email: "",
      reseller_phone: "",
      reseller_address: "",
      reseller_city: "",
      reseller_state: "",
      reseller_zip: "",
      reseller_details: [],
      service_success_message: "",
      service_already_eists_message: "",
      order_status: "pending",
      rejection_reason: "",
      rejectErrorMessage: "",
      rejectErrorMessageTitle: "",
      rejectSuccessMessage: "",
      rejectionButton: false,
      //Services
      service_grand_total: 0,
      service_grand_sub_total: 0,
      //Discount Entries
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
      discount_reason: "",
      //Shipping Entries
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
      order_date: "",
      dueDate: "",
      //Tax Details
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
      tax_reason: "",
      all_services: [],
      all_select_services: [],
      all_select_services_optional: [],
      select_service: "",
      select_name: "",
      select_a_service: null,
      select_service_optional: "",
      select_a_service_optional: null,
      initialState,
      initialStateOptional,
      select_name: "",
      quote_accountno: "",
      quote_title: "",
      quote_notes: "",
      email_sent: "No",
      //Quotation Settings
      quotation_notes: "",
      payment_terms: "",
      currency: "USD",
      contact_person: "",
      contact_email: "",
      validity: "",
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: false,
      conversations: [],
      rows_rfq_questions: [],
      rows_rfq_services: [],
      quote_comment: "",
      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      orderno: "",

      order_documents: [],
      allowed_documents: 0,
      orderDocumentLoader: true,
      orderDocumentForm: false,
      document_title: "",
      document_attachement: "",
      document_attachement_name: "",
      orderDocumentErrorTitle: "",
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
      upload_document_button: false,
      currentDocumentLink: "",
      currentDocumentType: "",

      invoice_button: false,
      invoiceErrorMessage: "",
      invoiceErrorMessageTitle: "",
      invoiceSuccessMessage: "",

      quick_books: [],
      quick_books_invocie: [],
      invoiceItems: [],
      quick_books_access_token: "",
      quick_book_realmId: "",
      quick_books_refresh_token: "",
      invoice_created: "no",

      invoiceAuthErrorMessage: "",
      invoiceAuthErrorMessageTitle: "",
      total_paid_amount: 0,
      total_remaining_amount: 0,
      agent_name: "",
      agent_comp_name: "",
      commission_approved: "0",
      total_commission: 0,
      total_commission_pay: 0,
      commissionError: "",
      commissionErrorTitle: "",
      commissionSuccessMessage: "",
      allow_commission_approval: false,
      commission_value_type: "",
      commission_value: 0,
      paid_commission_logs: [],
      orderCommissionLoader: true,

      rows: [],
      rows_service_temp: [
        {
          temp_serv_id: "row-135",
          temp_serv_data: {
            temp_serv_inner_id: 940,
            temp_serv_inner_name: "Item 1",
            temp_serv_inner_quantity: 2,
            temp_serv_inner_unit: "sbc",
            temp_serv_inner_price: 10,
            temp_serv_inner_total: 10,
            temp_serv_inner_type: "service",
          },
        },
      ],
      rows_optional: [],
      rows_optional_temp: [
        {
          temp_opt_id: "row-145",
          temp_opt_data: {
            temp_opt_inner_id: 940,
            temp_opt_inner_name: "Item 1",
            temp_opt_inner_quantity: 2,
            temp_opt_inner_unit: "sbc",
            temp_opt_inner_price: 10,
            temp_opt_inner_total: 10,
            temp_opt_inner_type: "service",
          },
        },
      ],
      rows_custom_temp: [
        {
          temp_id: "row-125",
          temp_data: {
            temp_inner_id: 94,
            temp_inner_name: "Item 1",
            temp_inner_quantity: 2,
            temp_inner_unit: "sbc",
            temp_inner_price: 10,
            temp_inner_total: 10,
            temp_inner_type: "custom",
          },
        },
      ],
    };
  }

  async componentDidMount() {
    let orderno = this.props.props.match.params.orderno;
    let client_accountno = this.props.props.match.params.client_accountno;
    let quote_accountno = this.props.props.match.params.quote_accountno;
    //Zc5DaPETvz7oXkd
    this.setState({
      client_accountno: client_accountno,
      rfq_accountno: "",
      quote_accountno: quote_accountno,
      quote_title: quote_accountno,
      orderno: orderno,
    });

    if (
      this.props.props.match.params.rfq_accountno &&
      this.props.props.match.params.rfq_accountno !== ""
    ) {
      // this.getSingleRfq(client_accountno, rfq_accountno);
      await this.getServices();
    }

    if (
      this.props.props.match.params.orderno &&
      this.props.props.match.params.orderno !== ""
    ) {
     
      await this.getSalesOrder(orderno);
      //this.getQuotationSettings(quote_accountno);
      
    }

    if (client_accountno !== "") {
      //this.getSingleClient(client_accountno);
    }
    await this.getOrderCommission(orderno);
    const previousUrl = sessionStorage.getItem("previousUrl");
    const autoPushInvoice = sessionStorage.getItem("autoPushInvoice");

    if (previousUrl && autoPushInvoice === "true") {
      sessionStorage.removeItem("previousUrl");
      sessionStorage.removeItem("autoPushInvoice");
      setTimeout(async () => {
        await this.openCreateInvoice();
        setTimeout(async () => {
          await this.create_external_invoice();
        }, 2000);
      }, 1000);
    }

    setTimeout(async () => {
      this.setState({pageLoader: false});
    }, 1000);
    
  }

  getSalesOrder = async (orderno) => {
    const servicesResponce = await getSingleSalesOrder(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    console.log("getSalesOrder: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let discount_value_total = 0;
      let discount_type = "amount";
      let discount_value = 0;
      let discount_reason = "";
      if (
        servicesResponce.data.data[0]["discounts"] &&
        servicesResponce.data.data[0]["discounts"].length > 0
      ) {
        discount_value_total =
          servicesResponce.data.data[0]["discounts"][0]["discount_total"];
        discount_type =
          servicesResponce.data.data[0]["discounts"][0]["discount_type"];
        discount_value =
          servicesResponce.data.data[0]["discounts"][0]["discount_value"];
        discount_reason =
          servicesResponce.data.data[0]["discounts"][0]["discount_reason"];
      }
      let tax_value_total = 0;
      let tax_type = "amount";
      let tax_value = 0;
      let tax_reason = "";
      if (
        servicesResponce.data.data[0]["taxes"] &&
        servicesResponce.data.data[0]["taxes"].length > 0
      ) {
        tax_value_total =
          servicesResponce.data.data[0]["taxes"][0]["tax_total"];
        tax_type = servicesResponce.data.data[0]["taxes"][0]["tax_type"];
        tax_value = servicesResponce.data.data[0]["taxes"][0]["tax_value"];
        tax_reason = servicesResponce.data.data[0]["taxes"][0]["tax_reason"];
      }
      let shipping_value_total = 0;
      let shipping_title = "";
      let shipping_value = "";
      if (
        servicesResponce.data.data[0]["shipping"] &&
        servicesResponce.data.data[0]["shipping"].length > 0
      ) {
        shipping_value_total =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
        shipping_title =
          servicesResponce.data.data[0]["shipping"][0]["shipping_title"];
        shipping_value =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
      }
      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      let total = 0;
      if (
        servicesResponce.data.data[0]["quote_services"] &&
        servicesResponce.data.data[0]["quote_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["quote_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              sku: rfq_services[i]["sku"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: rfq_services[i]["service_price"],
              total: Number(
                Number(rfq_services[i]["quantity"]) *
                  rfq_services[i]["service_price"]
              ).toFixed(this.decimalPoint),
              type: rfq_services[i]["item_type"],
              desc: rfq_services[i]["product_desc"],
              summary: rfq_services[i]["product_summary"],
            },
          };
          servicesRows.push(newArray);
          total =
            total +
            Number(rfq_services[i]["service_price"]) *
              Number(rfq_services[i]["quantity"]);
        }
      }
      //Minus Discount
      let with_discount = total - parseFloat(discount_value_total);
      //Calculate Tax
      with_discount = with_discount + parseFloat(tax_value_total);
      //Calculate Shipping
      with_discount = with_discount + parseFloat(shipping_value_total);

      //**** Get Optional Services ****/rows_optional
      let servicesOptionalRows = [];
      if (
        servicesResponce.data.data[0]["quote_optional_services"] &&
        servicesResponce.data.data[0]["quote_optional_services"].length > 0
      ) {
        let optional_services =
          servicesResponce.data.data[0]["quote_optional_services"];
        for (let i = 0; i < optional_services.length; i++) {
          let newArrays = {
            id: `row-${i}`,
            data: {
              id: optional_services[i]["service_id"],
              name: optional_services[i]["service_name"],
              quantity: optional_services[i]["quantity"],
              unit: optional_services[i]["service_unit"],
              price: optional_services[i]["service_price"],
              total: Number(
                Number(optional_services[i]["quantity"]) *
                  optional_services[i]["service_price"]
              ).toFixed(this.decimalPoint),
              type: optional_services[i]["item_type"],
            },
          };
          servicesOptionalRows.push(newArrays);
        }
      }
      let quoteStatus = "completed";
      
      if (
        servicesResponce.data.data[0]["client_accountno"] &&
        servicesResponce.data.data[0]["client_accountno"] !== ""
      ) {
        await this.getSingleClient(servicesResponce.data.data[0]["client_accountno"]);
      }
      
      let total_paid_amount = 0;
      let total_remaining_amount = with_discount;
      if (
        servicesResponce.data.data[0]["total_paid"] &&
        servicesResponce.data.data[0]["total_paid"] > 0
      ) {
        total_paid_amount = servicesResponce.data.data[0]["total_paid"];
        total_remaining_amount = with_discount - total_paid_amount;
      }
      console.log("order_status: ", servicesResponce.data.data[0].quote_status);
      
      this.setState({
        order_status: servicesResponce.data.data[0].quote_status,
        payment_status: servicesResponce.data.data[0]["payment_status"],
        rows: servicesRows,
        rows_optional: servicesOptionalRows,
        tableData: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["quote_services"],
        quote_title: servicesResponce.data.data[0]["quote_title"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        quote_attachement: servicesResponce.data.data[0]["quote_attachement"],
        
        all_answers: servicesResponce.data.all_answers,
        service_grand_total:
          with_discount && with_discount > 0
            ? this.formatAsCurrency(with_discount)
            : "0.00",
        service_grand_sub_total:
          total && total > 0 ? this.formatAsCurrency(total) : "0.00",
        total_paid_amount: total_paid_amount,
        total_remaining_amount: total_remaining_amount,
        discount_value_total: discount_value_total,
        discount_type: discount_type,
        discount_value: discount_value,
        discount_reason: discount_reason,
        tax_value_total: tax_value_total,
        tax_type: tax_type,
        tax_value: tax_value,
        tax_reason: tax_reason,
        shipping_value_total: shipping_value_total,

        shipping_value: shipping_value,
        shipping_title: shipping_title,
        email_sent: servicesResponce.data.data[0]["email_sent"],
        conversations: servicesResponce.data.data[0]["conversations"],

        
        order_date: servicesResponce.data.data[0]["dateCreated"],

        dueDate: servicesResponce.data.data[0]["dueDate"],
        payment_terms: servicesResponce.data.data[0]["payment_terms"],

        currency: servicesResponce.data.data[0]["currency"],
        validity: servicesResponce.data.data[0]["validity"],
        contact_person: servicesResponce.data.data[0]["contact_person"],
        
        client_accountno: servicesResponce.data.data[0]["client_accountno"],
        quote_accountno: servicesResponce.data.data[0]["quote_accountno"],
        rfq_accountno: servicesResponce.data.data[0]["rfq_accountno"],
        //Quickbook Data
        invoice_created: servicesResponce.data.invoice_created,
        quick_books: servicesResponce.data.quickLogData,
        quick_books_invocie: servicesResponce.data.quickInvoiceData,
        quick_books_access_token:
          servicesResponce.data.quickLogData[0]?.access_token || "",
        quick_book_realmId:
          servicesResponce.data.quickLogData[0]?.realmId || "",
        quick_books_refresh_token:
          servicesResponce.data.quickLogData[0]?.refresh_token || "",
        invoiceItems: servicesRows.map((row) => ({
          amount: Number(row.data.total),
          serviceName: row.data.name,
          qty: row.data.quantity,
          rate: Number(row.data.price),
          description: row.data.desc,
          summary: row.data.summary,
          sku: row.data.sku,
        })),
        //Quickbook Data
        tableLoader: false,
      });
      
    } else {
      // window.location.replace("/error");
    }
  };

  getSingleClient = async (client_accountno) => {
    const servicesResponce = await listSingleClient(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("getSingleClient: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.client_details,
        //quotation_notes: quotation_notes,
        //quote_notes: quotation_notes,
        client_name:
          servicesResponce.data.client_details[0]["name"] &&
          servicesResponce.data.client_details[0]["name"] !== ""
            ? servicesResponce.data.client_details[0]["name"]
            : "",
        client_company: servicesResponce.data.client_details[0]["comp_name"]
          ? servicesResponce.data.client_details[0]["comp_name"]
          : "",
        client_email: servicesResponce.data.client_details[0]["email"]
          ? servicesResponce.data.client_details[0]["email"]
          : "",
        client_phone: servicesResponce.data.client_details[0]["phone"]
          ? servicesResponce.data.client_details[0]["phone"]
          : "",
        client_address: servicesResponce.data.client_details[0]["address"]
          ? servicesResponce.data.client_details[0]["address"]
          : "",
        client_address2: servicesResponce.data.client_details[0]["address2"]
          ? servicesResponce.data.client_details[0]["address2"]
          : "",
        client_city: servicesResponce.data.client_details[0]["city"]
          ? servicesResponce.data.client_details[0]["city"]
          : "",
        client_state: servicesResponce.data.client_details[0]["state"]
          ? servicesResponce.data.client_details[0]["state"]
          : "",
        client_zip: servicesResponce.data.client_details[0]["zip"]
          ? servicesResponce.data.client_details[0]["zip"]
          : "",
        client_country: servicesResponce.data.client_details[0]["country"]
          ? servicesResponce.data.client_details[0]["country"]
          : "",
        agent_name: servicesResponce.data.client_details[0]["agent_name"],
        agent_comp_name:
          servicesResponce.data.client_details[0]["agent_comp_name"],
        reseller_details: servicesResponce.data.reseller_details,
        reseller_name: servicesResponce.data.reseller_details[0]["name"]
          ? servicesResponce.data.reseller_details[0]["name"]
          : "",
        reseller_company: servicesResponce.data.reseller_details[0]["comp_name"]
          ? servicesResponce.data.reseller_details[0]["comp_name"]
          : "",
        reseller_email: servicesResponce.data.reseller_details[0]["email"]
          ? servicesResponce.data.reseller_details[0]["email"]
          : "",
        reseller_phone: servicesResponce.data.reseller_details[0]["phone"]
          ? servicesResponce.data.reseller_details[0]["phone"]
          : "",
        reseller_address: servicesResponce.data.reseller_details[0]["address"]
          ? servicesResponce.data.reseller_details[0]["address"]
          : "",
        reseller_city: servicesResponce.data.reseller_details[0]["city"]
          ? servicesResponce.data.reseller_details[0]["city"]
          : "",
        reseller_state: servicesResponce.data.reseller_details[0]["state"]
          ? servicesResponce.data.reseller_details[0]["state"]
          : "",
        reseller_zip: servicesResponce.data.reseller_details[0]["zip"]
          ? servicesResponce.data.reseller_details[0]["zip"]
          : "",
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getPaymentMethod = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentMethodLoader: true,
      payErrorMessage: "",
    });
    const servicesResponce = await getPaymentMethod(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    //console.log("getPaymentMethod: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_method: servicesResponce.data.data[0]["payment_method"],
        payment_details: servicesResponce.data.data[0]["payment_details"],
        paymentMethodLoader: false,
      });
    } else {
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
      // window.location.replace("/error");
    }
    if (this.state.payErrorMessage !== "") {
      setTimeout(() => {
        this.setState({
          payErrorMessage: "",
        });
      }, 4000);
    }
  };

  getPaymentLogs = async () => {
    const { orderno } = this.state;
    this.setState({
      paymentLogLoader: true,
      payLogErrorMessage: "",
    });
    const servicesResponce = await getPaymentLogs(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    //console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentLogLoader: false,
        payLogErrorMessage:
          "There is some error while getting the payment logs.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_logs: servicesResponce.data.data,
        paymentLogLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        payment_logs: [],
        payLogErrorMessage: "Payment logs not found.",
        paymentLogLoader: false,
      });
    } else {
      this.setState({
        paymentLogLoader: false,
        payLogErrorMessage:
          "There is some error while getting the payment logs.",
      });
      // window.location.replace("/error");
    }
  };

  getOrderCommission = async (orderno) => {
    //const { orderno } = this.state;

    const servicesResponce = await getOrderCommission(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    console.log("getOrderCommission: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        commission_approved: servicesResponce.data.data[0]["approved"],
        total_commission: servicesResponce.data.data[0]["total_commission"],
        commission_value_type:
          servicesResponce.data.data[0]["commission_value_type"],
        commission_value: servicesResponce.data.data[0]["commission_value"],
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
    } else {
    }
  };

  approveCommission = async () => {
    const { order_status, payment_status, total_commission, orderno } =
      this.state;
    if (order_status === "completed") {
      this.setState({
        commissionError: "",
        commissionErrorTitle: "",
        allow_commission_approval: true,
        total_commission_pay: total_commission,
      });
      if (payment_status !== "paid") {
        this.setState({
          commissionError:
            "The commission cannot be approved at this time because the order has not been fully paid.",
          commissionErrorTitle: "Warning",
          allow_commission_approval: false,
        });
      }
    } else {
      this.setState({
        commissionError:
          "The commission cannot be approved at this time because the order has not been completed.",
        commissionErrorTitle: "Warning",
        allow_commission_approval: false,
      });
    }
    window.$("#approveCommisionModal").modal("show");
  };

  getOrderComments = async () => {
    const { orderno } = this.state;
    this.setState({
      orderCommentLoader: true,
      payLogErrorMessage: "",
    });
    const servicesResponce = await getOrderComments(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    //console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderCommentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        order_comments: servicesResponce.data.data,
        orderCommentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        payment_logs: [],

        orderCommentLoader: false,
      });
    } else {
      this.setState({
        orderCommentLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getOrderDocuments = async () => {
    const { orderno } = this.state;
    this.setState({
      orderDocumentLoader: true,
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
    });
    const servicesResponce = await getOrderDocuments(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    //console.log("getPaymentLogs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderDocumentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let allowed_documents = 0;
      if (servicesResponce.data.data) {
        allowed_documents = servicesResponce.data.data.length;
      }
      this.setState({
        order_documents: servicesResponce.data.data,
        allowed_documents: allowed_documents,
        orderDocumentLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        order_documents: [],
        orderDocumentLoader: false,
      });
    } else {
      this.setState({
        orderDocumentLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getOrderPaidCommission = async () => {
    const { orderno } = this.state;
    this.setState({
      orderCommissionLoader: true,
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
    });
    
    const servicesResponce = await getOrderPaidCommission(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    console.log("getOrderPaidCommission: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderCommissionLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      
      this.setState({
        paid_commission_logs: servicesResponce.data.data,
        orderCommissionLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        paid_commission_logs: [],
        orderCommissionLoader: false,
      });
    } else {
      this.setState({
        orderCommissionLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  openUploadDocument = async () => {
    this.setState({
      orderDocumentForm: true,
      orderDocumentErrorTitle: "",
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
      document_attachement: "",
      document_attachement_name: "",
    });
  };

  submitOrderDocument = async () => {
    let {
      orderno,
      document_title,
      document_attachement,
      document_attachement_name,
    } = this.state;
    this.setState({
      upload_document_button: true,
    });
    if (document_attachement_name === "") {
      this.setState({
        orderDocumentErrorMessage: "Document file is required.",
        orderDocumentErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          orderDocumentErrorTitle: "",
          orderDocumentErrorMessage: "",
          upload_document_button: false,
        });
      }, 4000);
      return false;
    } else if (document_title === "") {
      this.setState({
        orderDocumentErrorMessage: "Document title is required.",
        orderDocumentErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          orderDocumentErrorTitle: "",
          orderDocumentErrorMessage: "",
          upload_document_button: false,
        });
      }, 4000);
      return false;
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", document_attachement);
      formData.append("fileName", document_attachement_name);
      formData.append("orderno", orderno);
      formData.append("document_title", document_title);
      formData.append("added_by", "admin");

      try {
        axios
          .post(
            AGENT_SERVER_URL +
              "signed_agreements_external_api_calls/upload_order_document",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            //console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                orderDocumentErrorMessage: "Record not found.",
                orderDocumentErrorTitle: "System Error",
                upload_document_button: false,
                orderDocumentSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                orderDocumentErrorTitle: "System Error",
                orderDocumentErrorMessage: response.data.message,
                upload_document_button: false,
                orderDocumentSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                orderDocumentSuccessMessage: "Document uploaded successfully.",
                orderDocumentErrorMessage: "",
                orderDocumentErrorTitle: "",
                upload_document_button: true,
                orderDocumentForm: false,
                document_title: "",
                document_attachement: [],
                document_attachement_name: "",
              });
              setTimeout(() => {
                this.getOrderDocuments();
              }, 2000);
            } else {
              this.setState({
                orderDocumentErrorTitle: "System Error",
                orderDocumentErrorMessage:
                  "There is some error while uploading the document.",
                orderDocumentSuccessMessage: "",
                upload_document_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          orderDocumentErrorTitle: "System Error",
          orderDocumentErrorMessage:
            "There is some error while uploading the document.",
          disabled: false,
          orderDocumentSuccessMessage: "",
          upload_document_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          orderDocumentErrorMessage: "",
          orderDocumentErrorTitle: "",
        });
      }, 4000);
    }
  };

  deleteDocument = async (document_id) => {
    const { orderno } = this.state;
    this.setState({
      orderDocumentErrorMessage: "",
      orderDocumentSuccessMessage: "",
    });
    const servicesResponce = await deleteOrderDocument(
      auth.getAccount(),
      auth.getToken(),
      orderno,
      document_id
    );
    //console.log("deleteDocument: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        orderDocumentErrorTitle: "Delete Error",
        orderDocumentErrorMessage:
          "There is some error while deleting the document.",
        orderDocumentSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        orderDocumentErrorTitle: "",
        orderDocumentErrorMessage: "",
        orderDocumentSuccessMessage: "Order document deleted successfully.",
      });
      setTimeout(() => {
        this.getOrderDocuments();
      }, 3000);
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        orderDocumentErrorTitle: "Delete Error",
        orderDocumentErrorMessage:
          "There is some error while deleting the document.",
        orderDocumentSuccessMessage: "",
      });
      setTimeout(() => {
        this.getOrderDocuments();
      }, 3000);
    } else {
      this.setState({
        orderDocumentErrorTitle: "System Error",
        orderDocumentErrorMessage:
          "There is some error while deleting the document.",
        orderDocumentSuccessMessage: "",
      });
      // window.location.replace("/error");
    }
    if (this.state.orderDocumentErrorMessage !== "") {
      setTimeout(() => {
        this.setState({
          orderDocumentErrorMessage: "",
          orderDocumentErrorTitle: "",
        });
      }, 4000);
    }
  };

  getSingleRfqOld = async (client_accountno, rfq_accountno) => {
    const servicesResponce = await getSingleRfq(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      rfq_accountno
    );
    //console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      if (
        servicesResponce.data.data[0]["rfq_services"] &&
        servicesResponce.data.data[0]["rfq_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["rfq_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: 0,
              total: 0,
              type: "service",
            },
          };
          servicesRows.push(newArray);
        }
      }
      console.log("servicesRows: ", servicesRows);
      this.setState({
        rows_rfq_services: servicesRows,
        //tableData: servicesResponce.data.data,
        rows_rfq_questions: servicesResponce.data.data[0]["rfq_questions"],
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,

        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getServices = async () => {
    const servicesResponce = await listServicesWithPrice(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("getServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_select_services = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][1],
            value: servicesResponce.data.data[i][0],
          };
          all_select_services.push(newArr);
        }
      }
      this.setState({
        all_select_services: all_select_services,
        all_select_services_optional: all_select_services,
        all_services: servicesResponce.data.data,
      });
    }
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      //console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  defaultText = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "pending") {
      return <span className="badge badge-outline-primary">Pending</span>;
    } else if (is_default === "approved") {
      return <span className="badge badge-outline-success">Approved</span>;
    } else if (is_default === "cancelled") {
      return <span className="badge badge-outline-danger">Cancelled</span>;
    } else if (is_default === "in_review") {
      return <span className="badge badge-outline-warning">In Review</span>;
    }
  };

  /*showRequirements = (value, tableMeta) => {
    let rfq_accountno = tableMeta.rowData[0];

    let all_data = this.state.tableData;
    let rfq_requirements = "";
    for (let i = 0; i < all_data.length; i++) {
      if (rfq_accountno === all_data[i]["rfq_accountno"]) {
        rfq_requirements = all_data[i]["rfq_comments"];
      }
    }

    window.$("#modalFormOptions").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
        rfq_requirements: rfq_requirements,
      });
    }, 3000);
  };*/

  viewQuestionType = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];

    if (domain_str === "") {
      return "---";
    } else if (domain_str !== "") {
      return (
        <span className="badge badge-outline-dark">
          <em className="icon ni ni-card-view"></em> View
        </span>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download PDF"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em className="icon ni ni-download"></em> View Attachment
            </span>
          </a>
        </div>
      );
    } else {
      return "---";
    }
  };

  downloadPdfRfq = async (d_f_name) => {
    var url = d_f_name;
    let file_name = this.extractFileNameFromUrl(d_f_name);
    url = AGENT_PORTAL_URL + "files_data/rfq/" + file_name;
    //console.log("downloadPdfRfq: ", url);
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  isStringURL = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  downloadOrderPdf = async (d_f_name) => {
    //var url = d_f_name;
    let file_name = d_f_name;
    var url = AGENT_PORTAL_URL + "files_data/rfq/" + d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  extractFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname; // Gets the path after the domain
    const fileName = pathname.split("/").pop(); // Extracts the last segment after splitting by '/'
    return fileName;
  };

  deleteQuestions = async (value, tableMeta) => {
    let question_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteQuestions(
      auth.getAccount(),
      auth.getToken(),
      question_id
    );
    //console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Question deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
        successMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessage: "", successMessage: "" });
    }, 5000);
  };

  deleteOption = async (id, question_id) => {
    this.setState({
      optionErrorMessage: "",
      optionSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteAnswerOption(
      auth.getAccount(),
      auth.getToken(),
      id,
      question_id
    );
    //console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        optionErrorMessage:
          "There is some error while deleting the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        optionErrorMessage: "",
        optionSuccessMessage: "Answer option deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        optionErrorMessage:
          "There is some error while adding the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ optionErrorMessage: "", optionSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeAmount = (event) => {
    const { name, value } = event.target;
    const numericValue = Number(value); // Ensure the input is treated as a number
    this.setState({ amountExceedError: "" });
    
    // Validate the input value against total_remaining_amount
    if (numericValue <= this.state.total_remaining_amount) {
      this.setState({ [name]: numericValue }); // Update state if valid
      if (numericValue < this.state.total_remaining_amount) {
        this.setState({ payment_process: "partial" });
      } else if (numericValue === this.state.total_remaining_amount) {
        this.setState({ payment_process: "paid" });
      }
    } else {
      this.setState({
        amountExceedError:
          "The entered amount must be less than or equal to the total remaining amount $" +
          this.state.total_remaining_amount,
      });
    }
  };

  handleChangeCommission = (event) => {
    const { name, value } = event.target;
    const numericValue = Number(value); // Ensure the input is treated as a number
    this.setState({ amountExceedError: "" });
    // Validate the input value against total_remaining_amount
    if (numericValue > this.state.service_grand_total) {
      this.setState({
        amountExceedError:
          "The commission amount cannot be greater than order total $" +
          this.state.service_grand_total,
      });
    } else {
      this.setState({ [name]: numericValue }); // Update state if valid
    }
  };

  handleDateChange = (newDate) => {
    this.setState({ payment_date: newDate });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  openServices = () => {
    this.getServices();
    window.$("#modalFormServices").modal("show");
  };

  modalHide = (modalId) => {
    window.$("#" + modalId).modal("hide");
  };

  calculateTotalRate = async () => {
    const { rows, discount_value_total } = this.state;

    console.log("calculateTotalRate: ", rows);
    let total = 0;

    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        total =
          total + Number(rows[i].data.price) * Number(rows[i].data.quantity);
      }
    }
    //console.log("calculateTotalRate Total: ", total);
    let with_discount = total - parseFloat(discount_value_total);
    //Calculate Tax
    let tax_value_total = this.state.tax_value_total;
    with_discount = with_discount + parseFloat(tax_value_total);
    //Calculate Shipping
    let shipping_value_total = this.state.shipping_value_total;
    with_discount = with_discount + parseFloat(shipping_value_total);

    //Calculate Shipping and Discount

    //with_discount = with_discount + total_tax;
    console.table({
      with_discount_shipping_tax: with_discount,
      discount_value_total: discount_value_total,
      tax_value_total: tax_value_total,
      shipping_value_total: shipping_value_total,
    });
    console.log("service_grand_sub_total: ", total);
    console.log("service_grand_total: ", with_discount);
    this.setState({
      service_grand_total:
        with_discount && with_discount > 0
          ? with_discount.toFixed(this.decimalPoint)
          : "0.00",
      service_grand_sub_total:
        total && total > 0 ? total.toFixed(this.decimalPoint) : "0.00",
    });
  };

  handleChangeServicesCheckbox = (idx) => (e) => {
    const { target } = e;
    const services_rows = [...this.state.services_rows];
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    if (services_rows[idx]) {
      services_rows[idx][name] = value;
    } else {
      this.setState((prevState) => {
        const services_rows = [...prevState.services_rows];
        if (services_rows[idx]) {
          services_rows[idx][name] = value;
        }
      });
    }

    this.setState({
      services_rows,
      isBlocking: false,
    });
    if (name === "service_checked") {
      this.calculateTotalRate();
    }
    console.log("services_rows: ", this.state.services_rows);
    //
  };

  open_discount = () => {
    window.$("#modalFormDiscount").modal("show");
  };

  applyDiscount = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
        this.state.discount_value +
        " " +
        this.state.discount_type +
        " " +
        this.state.discount_reason +
        " service_grand_total: " +
        service_grand_total +
        " service_grand_sub_total: " +
        service_grand_sub_total
    );
    let total_with_discount = service_grand_total;
    let discount_value_total = 0;
    if (this.state.discount_type === "amount") {
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(this.state.discount_value);
      discount_value_total = parseFloat(this.state.discount_value);
      this.setState({
        discount_value_total: discount_value_total,
      });
    } else if (this.state.discount_type === "percentage") {
      let percentage = this.calculatePercent(
        parseFloat(this.state.discount_value),
        parseFloat(service_grand_sub_total)
      );
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(percentage);
      discount_value_total = parseFloat(percentage);
      this.setState({
        discount_value_total: discount_value_total,
      });
    }

    setTimeout(() => {
      this.calculateTotalRate();
      window.$("#modalFormDiscount").modal("hide");
    }, 500);

    //await this.calculateTotalRate();
  };

  removeDiscount = async () => {
    this.setState({
      discount_reason: "",
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
    });
    setTimeout(async () => {
      // Your async function here
      await this.applyDiscount();
    }, 500);
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(this.decimalPoint);
  };

  open_shipping = () => {
    window.$("#modalFormShipping").modal("show");
  };

  applyShipping = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
        this.state.shipping_title +
        " " +
        this.state.shipping_value +
        " " +
        this.state.shipping_value_total +
        " service_grand_total: " +
        service_grand_total +
        " service_grand_sub_total: " +
        service_grand_sub_total
    );

    let shipping_value_total = 0;

    shipping_value_total = parseFloat(this.state.shipping_value);

    this.setState({
      shipping_value_total: shipping_value_total,
    });
    window.$("#modalFormShipping").modal("hide");
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
    //await this.calculateTotalRate();
  };

  removeShipping = async () => {
    this.setState({
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
    });
    await this.applyShipping();
  };

  open_tax = async () => {
    window.$("#modalFormTax").modal("show");
  };

  applyTax = async () => {
    const {
      service_grand_total,
      service_grand_sub_total,
      discount_value_total,
    } = this.state;

    console.table({
      applyTax: "",
      tax_value: parseFloat(this.state.tax_value),
      tax_type: this.state.tax_type,
      service_grand_total: parseFloat(service_grand_total),
      service_grand_sub_total: parseFloat(service_grand_sub_total),
      discount_value_total: parseFloat(discount_value_total),
    });

    let tax_value_total = 0;
    if (this.state.tax_type === "amount") {
      tax_value_total = parseFloat(this.state.tax_value);
      this.setState({
        tax_value_total: tax_value_total,
      });
    } else if (this.state.tax_type === "percentage") {
      let discount_difference =
        parseFloat(service_grand_sub_total) - parseFloat(discount_value_total);
      let percentage = this.calculatePercent(
        parseFloat(this.state.tax_value),
        parseFloat(discount_difference)
      );

      console.log("Tax Percentage: ", percentage);
      tax_value_total = parseFloat(percentage);
      this.setState({
        tax_value_total: tax_value_total,
      });
    }
    setTimeout(() => {
      this.calculateTotalRate();
      window.$("#modalFormTax").modal("hide");
    }, 500);
  };

  removeTax = async () => {
    this.setState({
      tax_reason: "",
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
    });
    await this.applyTax();
  };

  addRowOld = () => {
    const item = {
      service_id: "",
      service_title: "",
      service_quantity: 1,
      service_unit: "lbs",
      service_price: 0,
      service_sub_total: 0,
    };
    this.setState({
      quotation_services: [...this.state.quotation_services, item],
    });
  };

  handleChangeServices = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const rows_service_temp = [...this.state.rows_service_temp];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    console.log("handleChangeServices rows: ", rows);
    let sub_total = 0;
    if (rows_service_temp) {
      for (let i = 0; i < rows_service_temp.length; i++) {
        console.log(
          "select_service: " + " " + rows_service_temp[i].temp_serv_id
        );
        if (idx == rows_service_temp[i].temp_serv_id) {
          if (name === "name") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_name = value;
          }
          if (name === "unit") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_unit = value;
          }
          if (name === "quantity") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity =
              value;
            sub_total =
              rows_service_temp[i].temp_serv_data.temp_serv_inner_price *
              parseInt(value);
            rows_service_temp[i].temp_serv_data.temp_serv_inner_total = Number(
              sub_total
            ).toFixed(this.decimalPoint);
          }

          if (name === "price") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_price = value;
            sub_total =
              rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity *
              parseFloat(value);
            rows_service_temp[i].temp_serv_data.temp_serv_inner_total = Number(
              sub_total
            ).toFixed(this.decimalPoint);
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_service_temp = [...prevState.rows_service_temp];
        if (rows_service_temp) {
          rows_service_temp.temp_serv_data[name] = value;
        }
      });
    }
    //Handle Actual Rows
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        console.log("select_service: " + " " + rows[i].id);
        if (idx == rows[i].id) {
          if (name === "name") {
            rows[i].data.name = value;
          }
          if (name === "unit") {
            rows[i].data.unit = value;
          }
          if (name === "quantity") {
            rows[i].data.quantity = value;
            sub_total = rows[i].data.price * parseInt(value);
            rows[i].data.total = Number(sub_total).toFixed(this.decimalPoint);
          }

          if (name === "price") {
            rows[i].data.price = value;
            sub_total = rows[i].data.quantity * parseFloat(value);
            rows[i].data.total = Number(sub_total).toFixed(this.decimalPoint);
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows = [...prevState.rows];
        if (rows) {
          rows.data[name] = value;
        }
      });
    }

    this.setState({
      rows_service_temp,
      rows,
      isBlocking: false,
    });
    if (name === "quantity" || name === "price") {
      this.calculateTotalRate();
    }
    console.log("rows_service_temp: ", this.state.rows_service_temp);
    //
  };

  handleChangeServicesOptional = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_optional = [...this.state.rows_optional];

    let sub_total = 0;
    if (rows_optional) {
      for (let i = 0; i < rows_optional.length; i++) {
        console.log("select_service: " + " " + rows_optional[i].id);
        if (idx == rows_optional[i].id) {
          if (name === "name") {
            rows_optional[i].data.name = value;
          }
          if (name === "unit") {
            rows_optional[i].data.unit = value;
          }
          if (name === "quantity") {
            rows_optional[i].data.quantity = value;
            if (value === "") {
              rows_optional[i].data.quantity = 1;
            }

            sub_total = rows_optional[i].data.price * Number(value);
            rows_optional[i].data.total = Number(sub_total).toFixed(
              this.decimalPoint
            );
          }

          if (name === "price") {
            rows_optional[i].data.price = value;
            sub_total =
              Number(rows_optional[i].data.quantity) * parseFloat(value);
            rows_optional[i].data.total = Number(sub_total).toFixed(
              this.decimalPoint
            );
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_optional = [...prevState.rows_optional];
        if (rows_optional) {
          rows_optional.data[name] = value;
        }
      });
    }

    this.setState({
      rows_optional,
      isBlocking: false,
    });

    console.log("services_rows: ", this.state.rows_optional);
    //
  };

  addNewService = async (row_id) => {
    const { select_service, rows, rows_service_temp } = this.state;

    if (rows_service_temp) {
      for (let i = 0; i < rows_service_temp.length; i++) {
        if (row_id == rows_service_temp[i].temp_serv_id) {
          this.setState((prevState) => ({
            rows: prevState.rows.filter((row) => row.id !== row_id),
          }));
          let selected_service_row = await this.getServiceWithId(
            rows_service_temp[i].temp_serv_data.temp_serv_inner_id
          );

          const newRow = {
            id: row_id,
            data: {
              id: rows_service_temp[i].temp_serv_data.temp_serv_inner_id,
              name: rows_service_temp[i].temp_serv_data.temp_serv_inner_name,
              quantity:
                rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity,
              unit: rows_service_temp[i].temp_serv_data.temp_serv_inner_unit,
              price: selected_service_row[8],
              total: Number(
                selected_service_row[8] *
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity
              ).toFixed(this.decimalPoint),
              type: "service",
            },
          };

          this.setState((prevState) => ({
            rows: [...prevState.rows, newRow],
            select_service: "",
            select_name: "",
          }));
        }
      }
      /*this.setState((prevState) => ({
        rows_service_temp: prevState.rows_service_temp.filter(
          (row) => row.temp_serv_id !== row_id
        ),
      }));*/
      setTimeout(() => {
        this.calculateTotalRate();
      }, 500);
    }
  };

  addNewServiceOptional = async (row_id) => {
    const { select_service_optional, rows_optional } = this.state;

    console.log(
      "select_service_optional: ",
      select_service_optional + " " + row_id
    );
    if (select_service_optional !== "") {
      let selected_service_row = await this.getServiceWithId(
        select_service_optional
      );
      console.log("selected_service_row: ", selected_service_row);
      if (selected_service_row && selected_service_row.length > 0) {
        let qty = 0;
        let unit = "";
        if (rows_optional) {
          for (let i = 0; i < rows_optional.length; i++) {
            if (row_id == rows_optional[i].id) {
              qty = rows_optional[i].data.quantity;
              unit = rows_optional[i].data.unit;
            }
          }
        }

        this.setState((prevState) => ({
          rows_optional: prevState.rows_optional.filter(
            (row) => row.id !== row_id
          ),
        }));
        const newRow = {
          id: row_id,
          data: {
            id: selected_service_row[0],
            name: selected_service_row[1],
            quantity: qty,
            unit: unit,
            price: selected_service_row[8],
            total: selected_service_row[8],
            type: "service",
          },
        };

        this.setState((prevState) => ({
          rows_optional: [...prevState.rows_optional, newRow],
          select_service_optional: "",
          select_name: "",
        }));

        /*setTimeout(() => {
          this.calculateTotalRate();
        }, 500);*/
        //window.$("#modalFormServices").modal("hide");
      }
    }
  };

  handleChangeSection = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    this.setState({
      select_name: value,
      isBlocking: false,
    });
  };

  handleChangeSectionOptional = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_optional = [...this.state.rows_optional];

    this.setState({
      select_name_optional: value,
      isBlocking: false,
    });
  };

  addNewSection = async (row_id) => {
    const { select_name, rows } = this.state;

    console.log("select_name: ", select_name + " " + row_id);
    if (select_name !== "") {
      this.setState((prevState) => ({
        rows: prevState.rows.filter((row) => row.id !== row_id),
      }));
      const newRow = {
        id: row_id,
        data: {
          id: "",
          name: select_name,
          quantity: "",
          unit: "",
          price: "",
          total: "",
          type: "section",
        },
      };

      this.setState((prevState) => ({
        rows: [...prevState.rows, newRow],
        select_service: "",
        select_name: "",
      }));
    }
  };

  addNewSectionOptional = async (row_id) => {
    const { select_name_optional, rows_optional } = this.state;

    console.log("select_name_optional: ", select_name_optional + " " + row_id);
    if (select_name_optional !== "") {
      this.setState((prevState) => ({
        rows_optional: prevState.rows_optional.filter(
          (row) => row.id !== row_id
        ),
      }));
      const newRow = {
        id: row_id,
        data: {
          id: "",
          name: select_name_optional,
          quantity: "",
          unit: "",
          price: "",
          total: "",
          type: "section",
        },
      };

      this.setState((prevState) => ({
        rows_optional: [...prevState.rows_optional, newRow],
        select_service: "",
        select_name_optional: "",
      }));
    }
  };

  handleChangeCustom = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_custom_temp = [...this.state.rows_custom_temp];
    const rows = [...this.state.rows];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    console.log("handleChangeCustom rows: ", rows_custom_temp);
    let sub_total = 0;
    if (rows_custom_temp) {
      for (let i = 0; i < rows_custom_temp.length; i++) {
        //console.log("select_service: " + " " + rows_custom_temp[i].id);
        if (idx == rows_custom_temp[i].temp_id) {
          if (name === "select_custom_id") {
            rows_custom_temp[i].temp_data.temp_inner_id = value;
          }
          if (name === "select_custom_name") {
            rows_custom_temp[i].temp_data.temp_inner_name = value;
          }
          if (name === "select_custom_unit") {
            rows_custom_temp[i].temp_data.temp_inner_unit = value;
          }
          if (name === "select_custom_quantity") {
            rows_custom_temp[i].temp_data.temp_inner_quantity = value;
            sub_total =
              rows_custom_temp[i].temp_data.temp_inner_price * parseInt(value);
            rows_custom_temp[i].temp_data.temp_inner_total = sub_total;
          }

          if (name === "select_custom_price") {
            rows_custom_temp[i].temp_data.temp_inner_price = value;
            sub_total =
              rows_custom_temp[i].temp_data.temp_inner_quantity *
              parseFloat(value);
            rows_custom_temp[i].temp_data.temp_inner_total = sub_total;
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_custom_temp = [...prevState.rows_custom_temp];
        if (rows_custom_temp) {
          rows_custom_temp.data[name] = value;
        }
      });
    }

    this.setState({
      rows_custom_temp,
      isBlocking: false,
    });

    if (name === "select_custom_quantity" || name === "select_custom_price") {
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          if (idx == rows[i].id) {
            if (rows[i].data.name !== "" && rows[i].data.id !== "") {
              if (name === "select_custom_id") {
                rows[i].data.id = value;
              }
              if (name === "select_custom_name") {
                rows[i].data.name = value;
              }
              if (name === "select_custom_unit") {
                rows[i].data.unit = value;
              }
              if (name === "select_custom_quantity") {
                rows[i].data.quantity = value;
                sub_total = rows[i].data.price * parseInt(value);
                rows[i].data.total = sub_total;
              }

              if (name === "select_custom_price") {
                rows[i].data.price = value;
                sub_total = rows[i].data.quantity * parseFloat(value);
                rows[i].data.total = sub_total;
              }
            }
          }
        }
        setTimeout(() => {
          this.calculateTotalRate();
        }, 500);
      }
    }

    console.log("this.state.rows_custom_temp: ", this.state.rows_custom_temp);
  };

  addNewCustomService = async (row_id) => {
    const { select_name, rows, rows_custom_temp } = this.state;

    console.log("select_name: ", select_name + " " + row_id);
    if (rows_custom_temp) {
      for (let i = 0; i < rows_custom_temp.length; i++) {
        if (row_id == rows_custom_temp[i].temp_id) {
          this.setState((prevState) => ({
            rows: prevState.rows.filter((row) => row.id !== row_id),
          }));
          const newRow = {
            id: row_id,
            data: {
              id: rows_custom_temp[i].temp_data.temp_inner_id,
              name: rows_custom_temp[i].temp_data.temp_inner_name,
              quantity: rows_custom_temp[i].temp_data.temp_inner_quantity,
              unit: rows_custom_temp[i].temp_data.temp_inner_unit,
              price: rows_custom_temp[i].temp_data.temp_inner_price,
              total: (
                Number(rows_custom_temp[i].temp_data.temp_inner_quantity) *
                Number(rows_custom_temp[i].temp_data.temp_inner_price)
              ).toFixed(this.decimalPoint),
              type: "custom",
            },
          };

          this.setState((prevState) => ({
            rows: [...prevState.rows, newRow],
            select_service: "",
            select_name: "",
          }));
        }
      }
      this.setState((prevState) => ({
        rows_custom_temp: prevState.rows_custom_temp.filter(
          (row) => row.temp_id !== row_id
        ),
      }));
      setTimeout(() => {
        this.calculateTotalRate();
      }, 500);
    }
  };

  getServiceWithId = async (select_service) => {
    const { all_services } = this.state;
    console.log("all_services: ", all_services);
    if (all_services && all_services.length > 0) {
      for (let i = 0; i < all_services.length; i++) {
        console.log(
          "select_service: " + select_service + " " + all_services[i][0]
        );
        if (select_service == all_services[i][0]) {
          return all_services[i];
        }
      }
    }
  };

  handleChangeSearchNew = (selectedOption, { action, row, index }) => {
    const { rows_service_temp } = this.state;
    // Your custom logic here
    console.log("selectedOption:", selectedOption);
    console.log("row:", row);
    //let select_a_service_ = "select_a_service_"+index;

    if (selectedOption) {
      this.setState((prevState) => ({
        select_service: selectedOption.value,
        [`select_a_service_${index}`]: selectedOption,
      }));
      if (rows_service_temp) {
        for (let i = 0; i < rows_service_temp.length; i++) {
          if (row.id == rows_service_temp[i].temp_serv_id) {
            this.setState((prevState) => ({
              rows_service_temp: prevState.rows_service_temp.filter(
                (rows) => rows.temp_serv_id !== row.id
              ),
            }));
            const newRow = {
              temp_serv_id: row.id,
              temp_serv_data: {
                temp_serv_inner_id: selectedOption.value,
                temp_serv_inner_name: selectedOption.label,
                temp_serv_inner_quantity:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity,
                temp_serv_inner_unit:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_unit,
                temp_serv_inner_price:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_price,
                temp_serv_inner_total: "",
                temp_serv_inner_type: "service",
              },
            };

            this.setState((prevState) => ({
              rows_service_temp: [...prevState.rows_service_temp, newRow],
            }));
          }
        }
      }
    } else {
      this.setState({ [`select_a_service_${index}`]: null });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    //console.log("handleChangeSearch idx: ", idx);
    if (changed_value) {
      this.setState((prevState) => ({
        select_service: changed_value.value,
        select_a_service: changed_value,
      }));
    } else {
      this.setState({ select_a_service: null });
    }
  };

  handleChangeSearchOptional = async (changed_value) => {
    console.log("handleChangeSearchOptional: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        select_service_optional: changed_value.value,
        select_a_service_optional: changed_value,
      }));
    } else {
      this.setState({ select_a_service_optional: null });
    }
  };

  handleRemoveSpecificRowGeneric = (idx) => () => {
    const quotation_services = [...this.state.quotation_services];
    quotation_services.splice(idx, 1);
    this.setState({ quotation_services });
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
  };

  handleInputChange = (index, event) => {
    const { services } = this.state;
    const { name, value } = event.target;

    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [name]: value };
      }
      return service;
    });

    this.setState({ services: updatedServices }, () => {
      this.calculateSubtotal(index);
    });
  };

  calculateSubtotal = (index) => {
    const { services } = this.state;
    const service = services[index];
    const subtotal = service.price * service.quantity;

    const updatedServices = services.map((s, i) => {
      if (i === index) {
        return { ...s, subtotal };
      }
      return s;
    });

    this.setState({ services: updatedServices });
  };

  /***** Drag Drop ****/

  handleDragEnd = (result) => {
    if (!result.destination) return;

    const rows = [...this.state.rows];
    const [reorderedRow] = rows.splice(result.source.index, 1);
    rows.splice(result.destination.index, 0, reorderedRow);

    this.setState({ rows });
  };

  handleDragEndOptional = (result) => {
    if (!result.destination) return;

    const rows_optional = [...this.state.rows_optional];
    const [reorderedRow] = rows_optional.splice(result.source.index, 1);
    rows_optional.splice(result.destination.index, 0, reorderedRow);

    this.setState({ rows_optional });
  };

  addRow = () => {
    this.getServices();
    let id = `row-${Date.now()}`;

    const newRow = {
      id: id,
      data: {
        id: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    const newRows = {
      temp_serv_id: id,
      temp_serv_data: {
        temp_serv_inner_id: "",
        temp_serv_inner_name: "",
        temp_serv_inner_quantity: 1,
        temp_serv_inner_unit: "",
        temp_serv_inner_price: 0,
        temp_serv_inner_total: 0,
        temp_serv_inner_type: "service",
      },
    };

    this.setState((prevState) => ({
      rows: [...prevState.rows, newRow],
      rows_service_temp: [...prevState.rows_service_temp, newRows],
    }));
    console.log("addRow rows_service_temp: ", this.state.rows_service_temp);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addRowOptional = () => {
    this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    this.setState((prevState) => ({
      rows_optional: [...prevState.rows_optional, newRow],
    }));
  };

  addRowSec = () => {
    //this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "section",
      },
    };

    this.setState((prevState) => ({
      rows: [...prevState.rows, newRow],
    }));
  };

  addRowSecOptional = () => {
    //this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "section",
      },
    };

    this.setState((prevState) => ({
      rows_optional: [...prevState.rows_optional, newRow],
    }));
  };

  addRowCustom = () => {
    //this.getServices();
    let id = `row-${Date.now()}`;
    const newRow = {
      temp_id: id,
      temp_data: {
        temp_inner_id: "",
        temp_inner_name: "",
        temp_inner_quantity: 1,
        temp_inner_unit: "",
        temp_inner_price: 0,
        temp_inner_total: 0,
        temp_inner_type: "custom",
      },
    };

    const newRow2 = {
      id: id,
      data: {
        id: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "custom",
      },
    };

    this.setState((prevState) => ({
      rows_custom_temp: [...prevState.rows_custom_temp, newRow],
      rows: [...prevState.rows, newRow2],
    }));
  };

  removeRow = (id) => {
    this.setState((prevState) => ({
      rows: prevState.rows.filter((row) => row.id !== id),
    }));
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
  };

  removeRowOptional = (id) => {
    this.setState((prevState) => ({
      rows_optional: prevState.rows_optional.filter((row) => row.id !== id),
    }));
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  format_date_time = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy H:MM:ss");
    } else {
      return "---";
    }
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      quote_notes: content,
    });
    //console.log("Content was updated:", content);
  };

  close_modal = (modal_id) => {
    window.$("#" + modal_id).modal("hide");
  };

  save_as_draft = async () => {
    const {
      discount_type,
      discount_value,
      discount_value_total,
      discount_reason,
      shipping_title,
      shipping_value,
      shipping_value_total,
      tax_type,
      tax_value,
      tax_value_total,
      tax_reason,
      quote_accountno,
      quote_title,
      quote_notes,
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      rows,
      rows_optional,
      client_accountno,
      rfq_accountno,
      service_grand_sub_total,
      service_grand_total,
    } = this.state;
    //console.log("items_list: ", rows);
    //console.log("items_list_optional: ", rows_optional);
    if (rows && rows.length > 0) {
      this.setState({ draft_button: true, disabled: true });
      const servicesResponce = await saveQuotationAsDraft(
        auth.getAccount(),
        auth.getToken(),
        discount_type,
        discount_value,
        discount_value_total,
        discount_reason,
        shipping_title,
        shipping_value,
        shipping_value_total,
        tax_type,
        tax_value,
        tax_value_total,
        tax_reason,
        quote_accountno,
        quote_title,
        quote_notes,
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        JSON.stringify(rows),
        JSON.stringify(rows_optional),
        client_accountno,
        rfq_accountno,
        service_grand_sub_total,
        service_grand_total
      );
      //console.log("saveQuotationAsDraft: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while saving the quotation.",
          draft_button: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Quotation saved successfully.",
          tableLoader: false,
          draft_button: false,
          disabled: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while saving the quotation.",
          draft_button: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please add order lines to proceed.",
        draft_button: false,
        disabled: false,
      });
    }
    document
      .getElementById("editQuotations")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  send_to_customer = async () => {
    const {
      discount_type,
      discount_value,
      discount_value_total,
      discount_reason,
      shipping_title,
      shipping_value,
      shipping_value_total,
      tax_type,
      tax_value,
      tax_value_total,
      tax_reason,
      quote_accountno,
      quote_title,
      quote_notes,
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      rows,
      rows_optional,
      client_accountno,
      rfq_accountno,
      service_grand_sub_total,
      service_grand_total,
    } = this.state;
    //console.log("items_list: ", rows);
    //console.log("items_list_optional: ", rows_optional);
    if (rows && rows.length > 0) {
      this.setState({ send_client_button: true, disabled: true });
      const servicesResponce = await saveQuotationAndSent(
        auth.getAccount(),
        auth.getToken(),
        discount_type,
        discount_value,
        discount_value_total,
        discount_reason,
        shipping_title,
        shipping_value,
        shipping_value_total,
        tax_type,
        tax_value,
        tax_value_total,
        tax_reason,
        quote_accountno,
        quote_title,
        quote_notes,
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        JSON.stringify(rows),
        JSON.stringify(rows_optional),
        client_accountno,
        rfq_accountno,
        service_grand_sub_total,
        service_grand_total
      );
      //console.log("saveQuotationAndSendToCustomer: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while saving the quotation.",
          send_client_button: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Quotation sent successfully to the client.",
          tableLoader: false,
          send_client_button: false,
          disabled: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while saving the quotation.",
          send_client_button: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please add order lines to proceed.",
        draft_button: false,
        disabled: false,
      });
    }
    document
      .getElementById("editQuotations")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  edit_quotation_info = () => {
    window.$("#modalDefaultInfo").modal("show");
  };

  save_quotation_info = async () => {
    const {
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      quote_accountno,
      client_accountno,
    } = this.state;
    this.setState({
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: true,
    });
    const servicesResponce = await saveQuotationInformation(
      auth.getAccount(),
      auth.getToken(),
      quote_accountno,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      client_accountno
    );
    //console.log("save_quotation_info: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageInfo: "There is some error while saving the info.",
        info_button: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageInfo: "",
        successMessageInfo: "Quotation information saved successfully.",
        info_button: false,
      });
    } else {
      this.setState({
        errorMessageInfo: "There is some error while saving the info.",
        info_button: false,
      });
    }

    setTimeout(() => {
      this.setState({
        errorMessageInfo: "",
        successMessageInfo: "",
      });
    }, 4000);
  };

  downloadPdf = async (d_f_name) => {
    if (d_f_name !== "") {
      this.setState({ download_start: true });
      var url = AGENT_PORTAL_URL + "files_data/rfq/" + d_f_name;
      //console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ download_start: false });
        })
        .catch((error) => {
          this.setState({
            convErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ convErrorMessage: "", download_start: false });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  downloadReceipt = async (d_f_name) => {
    if (d_f_name !== "") {
      this.setState({ download_start: true });
      var url = AGENT_PORTAL_URL + "files_data/receipts/" + d_f_name;
      //console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ download_start: false });
        })
        .catch((error) => {
          this.setState({
            convErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ convErrorMessage: "", download_start: false });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  addToCart = async (service_id) => {
    const { all_services, rows } = this.state;
    this.setState({
      service_already_eists_message: "",
      service_success_message: "",
    });
    //console.log("addToCart service_id: ", service_id);
    //Check if service exists
    let service_exists = false;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].data.id === service_id) {
        service_exists = true;
      }
    }
    if (service_exists === true) {
      this.setState({
        service_already_eists_message: "Product already exists in our cart.",
      });
      window.$("#modalAlertCart").modal("show");
    } else {
      let selected_service_row = await this.getServiceWithId(service_id);
      if (selected_service_row) {
        const newRow = {
          id: `row-${Date.now()}`,
          data: {
            id: selected_service_row[0],
            name: selected_service_row[1],
            quantity: 1,
            unit: "",
            price: selected_service_row[8],
            total: Number(selected_service_row[8] * 1).toFixed(
              this.decimalPoint
            ),
            type: "service",
          },
        };

        this.setState((prevState) => ({
          rows: [...prevState.rows, newRow],
          service_success_message: "Product successfully added to the cart.",
        }));
        setTimeout(() => {
          this.calculateTotalRate();
        }, 500);
        window.$("#modalAlertCartSuccess").modal("show");
        console.log("addToCart selected_service_row: ", selected_service_row);
      } else {
        this.setState({
          service_already_eists_message: "Product does not exist.",
        });
        window.$("#modalAlertCart").modal("show");
      }
    }
  };

  addComment = async () => {
    const { quote_comment, quote_accountno, client_accountno } = this.state;
    this.setState({ quote_comment_button: true, convErrorMessage: "" });
    if (quote_comment !== "") {
      const servicesResponce = await saveQuotationComment(
        auth.getAccount(),
        auth.getToken(),
        quote_accountno,
        client_accountno,
        quote_comment
      );
      //console.log("save_quotation_info: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          convErrorMessage: "There is some error while adding the comment.",
          quote_comment_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          conversations: servicesResponce.data.conversations,
          convErrorMessage: "",
          quote_comment_button: false,
          quote_comment: "",
        });
      } else {
        this.setState({
          convErrorMessage: "There is some error while adding the comment.",
          quote_comment_button: false,
        });
      }
    } else {
      this.setState({
        convErrorMessage: "Please enter the comment.",
        quote_comment_button: false,
      });
    }

    setTimeout(() => {
      this.setState({
        convErrorMessage: "",
      });
    }, 4000);
  };

  markAsPaid = async () => {
    const {
      orderno,
      payment_notes,
      payment_date,
      payment_method_pay,
      pay_amount,
      payment_process,
      total_remaining_amount,
    } = this.state;
    this.setState({
      payment_button: true,
      payErrorMessage: "",
      paySuccessMessage: "",
      amountExceedError: "",
    });
    console.log("payment_process: " + payment_process + " pay_amount: " + pay_amount + " total_remaining_amount: " + total_remaining_amount);
    if (pay_amount !== "" && pay_amount > 0) {
      if (payment_process === "paid" && pay_amount < total_remaining_amount) {
        this.setState({
          payErrorMessage:
            "Please select Partial Payment if amount is less than the remaining amount.",
          payment_button: false,
        });
        setTimeout(() => {
          this.setState({
            payErrorMessage: "",
          });
        }, 5000);
        return;
      }

      if (
        payment_process === "partial" &&
        pay_amount === total_remaining_amount
      ) {
        this.setState({
          payErrorMessage:
            "Please select Full Payment if amount is equal to the remaining amount.",
          payment_button: false,
        });
        setTimeout(() => {
          this.setState({
            payErrorMessage: "",
          });
        }, 5000);
        return;
      }

      const servicesResponce = await markOrderAsPaid(
        auth.getAccount(),
        auth.getToken(),
        orderno,
        payment_notes,
        payment_date,
        payment_method_pay,
        pay_amount,
        payment_process
      );
      //console.log("save_quotation_info: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          payErrorMessage: "There is some error while processing your request.",
          payment_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          paySuccessMessage:
            "Payment of $" +
            pay_amount +
            " has been paid for the orderno #: " +
            orderno + ". Please wait while we are refreshing the page.",
          //payment_status: servicesResponce.data.data,
          //order_status: servicesResponce.data.order_status,
          payErrorMessage: "",
          //payment_button: false,
          //pay_amount: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
        /*setTimeout(async () => {
          await this.getSalesOrder(orderno);
        }, 3000);*/
        
      } else {
        this.setState({
          payErrorMessage: "There is some error while processing your request.",
          payment_button: false,
        });
      }
    } else {
      this.setState({
        payErrorMessage:
          "Please enter the amount and it must be greater than 0.",
        payment_button: false,
      });
    }
    if (
      this.state.payErrorMessage !== "" ||
      this.state.paySuccessMessage !== ""
    ) {
      setTimeout(() => {
        this.setState({
          payErrorMessage: "",
          paySuccessMessage: "",
        });
      }, 4000);
    }
  };

  approveCommissionSubmit = async () => {
    const {
      orderno,
      total_commission_pay,
      service_grand_total,
      agent_name,
      agent_comp_name
    } = this.state;
    this.setState({
      commission_pay_button: true,
      commissionError: "",
      commissionErrorTitle: "",
      commissionSuccessMessage: "",
    });
    console.log("total_commission_pay: " + total_commission_pay + " service_grand_total: " + service_grand_total);
    if (total_commission_pay === "" || total_commission_pay === 0) {
      
        this.setState({
          commissionErrorTitle: "Amount Error",
          commissionError: "Commission cannot be empty or 0.",
          commission_pay_button: false,
        });
        setTimeout(() => {
          this.setState({
            commissionError: "",
            commissionErrorTitle: "",
          });
        }, 5000);
        return;
      }

      if (
        total_commission_pay > service_grand_total
      ) {
        this.setState({
          commissionErrorTitle: "Amount Error",
          commissionError: "Commission cannot be greater than order total.",
          commission_pay_button: false,
        });
        setTimeout(() => {
          this.setState({
            commissionError: "",
            commissionErrorTitle: "",
          });
        }, 5000);
        return;
      }

      const servicesResponce = await approveCommission(
        auth.getAccount(),
        auth.getToken(),
        orderno,
        total_commission_pay,
      );
      //console.log("save_quotation_info: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionError: "There is some error while approving the commission.",
          commission_pay_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          commissionSuccessMessage:
            "Commission of $" +
            total_commission_pay +
            " has been approved for the channel partner : " +
            agent_name + " ( " + agent_comp_name + " ). Please wait page will refresh automatically.",
        });
        
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionError: "There is some error while approving the commission.",
          commission_pay_button: false,
        });
      }
    
    if (
      this.state.commissionError !== ""
    ) {
      setTimeout(() => {
        this.setState({
          commissionError: "",
            commissionErrorTitle: "",
        });
      }, 4000);
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".doc",
      ".docx",
      ".csv",
      ".xlsx",
      ".xls",
      ".txt",
      ".rtf",
      ".zip",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        orderDocumentErrorTitle: "Invalid File",
        orderDocumentErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 100MB)
    const maxSizeBytes = 100 * 1024 * 1024; // 100MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        orderDocumentErrorTitle: "File Size",
        orderDocumentErrorMessage:
          "File size exceeds the limit (100MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      document_attachement: selectedFile,
      orderDocumentErrorTitle: "",
      orderDocumentErrorMessage: "",
      document_attachement_name: selectedFile.name,
    });
    //return true;
  };

  getFileExtension = (fileName) => {
    let file_extension = fileName.split(".").pop();

    if (
      file_extension === "xlsx" ||
      file_extension === "xls" ||
      file_extension === "doc" ||
      file_extension === "docx" ||
      file_extension === "csv"
    ) {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.downloadDocument(fileName)}
        >
          <span class="badge badge-outline-secondary">
            <em class="icon ni ni-download"></em> Download
          </span>
        </a>
      );
    } else if (
      file_extension === "png" ||
      file_extension === "jpg" ||
      file_extension === "jpeg" ||
      file_extension === "pdf" ||
      file_extension === "txt" ||
      file_extension === "rtf"
    ) {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.viewDocument(fileName)}
        >
          <span class="badge badge-outline-secondary">
            <em class="icon ni ni-file-check-fill"></em> View
          </span>
        </a>
      );
    }
  };

  downloadDocument = async (d_f_name) => {
    if (d_f_name !== "") {
      var url = AGENT_PORTAL_URL + "files_data/rfq/" + d_f_name;
      const parts = url.split("/");
      let file_name = d_f_name;

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            orderDocumentErrorTitle: "Download Error",
            orderDocumentErrorMessage:
              "There is some error while downloading the document.",
          });
          setTimeout(() => {
            this.setState({
              orderDocumentErrorMessage: "",
              orderDocumentErrorTitle: "",
            });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  retrieveFileExtension = (fileName) => {
    return fileName.split(".").pop();
  };

  viewDocument = async (file_name) => {
    var url = AGENT_PORTAL_URL + "files_data/rfq/" + file_name;
    let file_extension = this.retrieveFileExtension(file_name);
    let current_type = "application/pdf";
    if (file_extension === "png") {
      current_type = "image/png";
    } else if (file_extension === "jpg" || file_extension === "jpeg") {
      current_type = "image/jpeg";
    } else if (file_extension === "txt") {
      current_type = "text/plain";
    } else if (file_extension === "rtf") {
      current_type = "application/rtf";
    }
    this.setState({
      currentDocumentLink: url,
      currentDocumentType: current_type,
    });
    window.$("#modalMarketingPDFLink").modal("show");
  };

  modalHideMarketingPDF = () => {
    this.setState({
      currentDocumentLink: "",
      currentDocumentType: "",
    });
    window.$("#modalMarketingPDFLink").modal("hide");
  };

  fieldValidation = () => {
    let { emailto, emailsubject, emailcontent } = this.state;

    if (emailto === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "To email address is required.",
      });
      return false;
    } else if (this.validateEmailAdd(emailto) === false) {
      this.setState({
        emailErrorMessageTitle: "Invalid Format",
        emailErrorMessage: "Invalid email address format.",
      });
      return false;
    } else if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Subject is required.",
      });
      return false;
    } else if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Custom content is required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        emailErrorMessage: "",
      });
    }
    return true;
  };

  openCreateInvoice = async () => {
    const { orderno, quick_books } = this.state;

    if (this.state.quick_books && this.state.quick_books.length > 0) {
      this.setState({
        invoice_button: false,
        invoiceErrorMessage: "",
        invoiceErrorMessageTitle: "",
        invoiceSuccessMessage: "",
      });
      window.$("#modalCreateInvoice").modal("show");
    } else {
      window.$("#modalAlertInvoice").modal("show");
    }
  };

  create_external_invoice = async () => {
    const { orderno, client_accountno, invoiceItems, dueDate, payment_terms } =
      this.state;

    // Reset messages and disable the button
    this.setState({
      invoiceSuccessMessage: "",
      invoiceErrorMessage: "",
      disabled: true,
      invoice_button: true,
    });

    // Validate if any required fields are missing
    const missingFields = invoiceItems.some(
      (item) => !item.sku || !item.summary || !item.description
    );

    // Convert grand total string to number
    const grandTotalString = this.state.service_grand_total;
    const grandTotalNumber = Number(grandTotalString.replace("$", "")).toFixed(
      2
    );

    try {
      const agreementResponce = await createExternalInvoice(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        orderno,
        this.state.client_name,
        this.state.client_email,
        this.state.client_phone,
        this.state.client_address,
        this.state.client_city,
        this.state.client_state,
        this.state.client_zip,
        this.state.quick_books_access_token,
        this.state.quick_book_realmId,
        this.state.quick_books_refresh_token,
        invoiceItems,
        this.state.discount_value_total,
        this.state.shipping_value_total,
        this.state.tax_value_total,
        grandTotalNumber,
        this.state.discount_type,
        this.state.tax_type,
        auth.getCompName(),
        dueDate,
        payment_terms
      );

      console.log("create_external_invoice: ", agreementResponce.data);

      // Handle various response statuses
      if (
        agreementResponce.data.status === 403 ||
        agreementResponce.data.errors === "authentication missing" ||
        agreementResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        agreementResponce.data.status === 404 &&
        agreementResponce.data.message === "fail" &&
        agreementResponce.data.note !== ""
      ) {
        this.setState({
          invoiceErrorMessage:
            "There is some error while processing the request.",
          invoiceErrorMessageTitle: "Error",
          invoiceSuccessMessage: "",
          disabled: false,
          invoice_button: false,
        });
      } else if (
        agreementResponce.data.status === 404 &&
        agreementResponce.data.message ===
          "Authorization failed. Please try again." &&
        agreementResponce.data.error === "[object Object]"
      ) {
        let countdown = 3;
        this.setState({
          invoiceAuthErrorMessageTitle: "Authorization",
          invoiceAuthErrorMessage: `Connecting to QuickBooks... ${countdown} seconds remaining`,
          invoiceSuccessMessage: "",
          disabled: false,
          invoice_button: false,
        });
        const countdownInterval = setInterval(() => {
          countdown -= 1;
          if (countdown > 0) {
            this.setState({
              invoiceAuthErrorMessage: `Connecting to QuickBooks... ${countdown} seconds remaining`,
            });
          } else {
            clearInterval(countdownInterval);
            this.redirectToAuth();
          }
        }, 1000);
      } else if (
        agreementResponce.data.status === 404 &&
        agreementResponce.data.message ===
          "Authorization failed. Please try again."
      ) {
        this.setState({
          invoiceErrorMessage: `There is some error while creating the Invoice to Quickbooks.`,
          invoiceErrorMessageTitle: "Error",
          invoiceSuccessMessage: "",
          disabled: false,
          invoice_button: false,
        });
      } else if (
        agreementResponce.data.status === 200 &&
        agreementResponce.data.message ===
          "Invoice created and stored successfully."
      ) {
        this.setState({
          invoiceErrorMessage: "",
          invoiceErrorMessageTitle: "",
          invoiceSuccessMessage: "Invoice pushed successfully.",
          invoice_button: false,
        });

        setTimeout(() => {
          window.$("#modalCreateInvoice").modal("hide");
          window.location.reload();
        }, 2000);
      } else {
        this.setState({
          invoiceErrorMessage:
            "There is some error while processing the request.",
          invoiceErrorMessageTitle: "Error",
          invoiceSuccessMessage: "",
          disabled: false,
          invoice_button: false,
        });
      }
    } catch (error) {
      console.error("Error creating external invoice:", error);
      this.setState({
        invoiceErrorMessage: "An unexpected error occurred.",
        invoiceErrorMessageTitle: "Error",
        invoiceSuccessMessage: "",
        disabled: false,
        invoice_button: false,
      });
    }
  };

  redirectToAuth = () => {
    const clientId = process.env.REACT_APP_QUICKBOOK_CLIENT_ID;
    const redirectUri = encodeURIComponent(`${APP_URL}Integrations`);
    const authBaseUrl = process.env.REACT_APP_QUICKBOOK_AUTH_BASE_URL;

    const randomState = Array.from({ length: 14 }, () =>
      Math.floor(Math.random() * 10)
    ).join("");

    const authUrl = `${authBaseUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=com.intuit.quickbooks.accounting&state=${randomState}`;

    sessionStorage.setItem("previousUrl", window.location.href);
    sessionStorage.setItem("autoPushInvoice", "true");

    // Redirect the user to the authorization URL
    window.location.href = authUrl;
  };

  modalHideRemoveAlert = (modal_id) => {
    window.$("#" + modal_id).modal("hide");
  };

  modalHideRemove = (modal_id) => {
    window.$("#" + modal_id).modal("hide");
  };

  openEmailWindow = async () => {
    const { orderno } = this.state;
    this.setState({
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
    });
    window.$("#modalFormEmail").modal("show");
  };

  submitOrderEmail = async () => {
    const { orderno, emailto, emailcc, emailbcc, emailsubject, emailcontent } =
      this.state;
    const modalBody = document.getElementById("modalFormEmail");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        emailErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      const servicesResponce = await sendOrderEmail(
        auth.getAccount(),
        auth.getToken(),
        orderno,
        emailto,
        emailcc,
        emailbcc,
        emailsubject,
        emailcontent
      );
      console.log("submitOrderEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage: "Email sent successfully.",
          //payment_status: servicesResponce.data.data,
          emailErrorMessageTitle: "",
          emailErrorMessage: "",
          emailsubject: "",
          emailcontent: "",
          emailto: "",
          email_button: false,
        });
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }
    if (
      this.state.emailErrorMessage !== "" ||
      this.state.emailSuccessMessage !== ""
    ) {
      setTimeout(() => {
        this.setState({
          emailErrorMessage: "",
          emailSuccessMessage: "",
        });
      }, 4000);
    }
  };

  showRequirements = async () => {
    const { rfq_accountno, client_accountno } = this.state;
    this.setState({
      formLoader: true,
      rfq_requirements: "",
    });

    const servicesResponce = await getSingleRfq(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      rfq_accountno
    );
    //console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        rfq_details: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "radio answer answer_id: " +
                rfq_questions[i]["answer_id"] +
                " " +
                answer_id
            );
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "checkbox answer answer_id: " +
                JSON.parse(rfq_questions[i]["answer_id"]) +
                " " +
                answer_id
            );
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      this.setState({
        rfq_details: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,
      });
    } else {
      this.setState({
        rfq_details: [],
      });
      // window.location.replace("/error");
    }

    window.$("#modalFormOptions").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  rejectSalesOrder = async () => {
    const { orderno } = this.state;
    this.setState({
      rejection_reason: "",
      rejectErrorMessage: "",
      rejectSuccessMessage: "",
    });
    window.$("#modalRejectSalesOrder").modal("show");
  };

  rejectQuotationSubmit = async () => {
    const { orderno, rejection_reason } = this.state;
    this.setState({
      rejectionButton: true,
      rejectErrorMessage: "",
      rejectSuccessMessage: "",
    });
    if (rejection_reason === "") {
      this.setState({
        rejectErrorMessage: "Please enter the reason.",
        rejectErrorMessageTitle: "Missing Fields",
      });
    } else {
      const rejectionResponse = await rejectSalesOrder(
        auth.getAccount(),
        auth.getToken(),
        orderno,
        rejection_reason
      );
      //console.log("rejectionResponse: ", rejectionResponse.data);
      if (
        rejectionResponse.data.status === 403 ||
        rejectionResponse.data.errors === "authentication missing" ||
        rejectionResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        rejectionResponse.data.status === 404 &&
        rejectionResponse.data.message === "not_found"
      ) {
        this.setState({
          rejectErrorMessage: "Sale order not found.",
          rejectErrorMessageTitle: "Missing Sale Order",
          rejectSuccessMessage: "",
          rejectionButton: false,
        });
      } else if (
        rejectionResponse.data.status === 200 &&
        rejectionResponse.data.message === "success"
      ) {
        this.setState({
          rejectErrorMessage: "",
          rejectErrorMessageTitle: "",
          rejectSuccessMessage: "Sale order rejected successfully.",
          rejectionButton: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          rejectErrorMessage:
            "There is some error while processing your request.",
          rejectErrorMessageTitle: "Sale Order Error",
          rejectSuccessMessage: "",
          rejectionButton: false,
        });
      }
      setTimeout(() => {
        this.setState({
          rejectErrorMessage: "",
          rejectErrorMessageTitle: "",
          rejectSuccessMessage: "",
        });
      }, 4000);
    }
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);

    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: this.decimalPoint,
      maximumFractionDigits: this.decimalPoint,
    });
  };

  render() {
    let paymentDetails = null;

    if (
      this.state.quick_books_invocie &&
      this.state.quick_books_invocie.length > 0
    ) {
      try {
        paymentDetails =
          typeof this.state.quick_books_invocie[0].payment_details === "string"
            ? JSON.parse(this.state.quick_books_invocie[0].payment_details)
            : this.state.quick_books_invocie[0].payment_details;
      } catch (error) {
        console.error("Error parsing payment details:", error);
      }
    }
    console.log("this.decimalPoint: ", this.decimalPoint);
    return (
      <div className="nk-content " id="editQuotations">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>

                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/SalesOrders/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Sale Orders
                      </Link>
                      <Typography color="textPrimary" className="">
                        Order Details
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>

              {/* Table 5th Col Start */}
              <div class="nk-block">
                {this.state.pageLoader === true ? (
                  InvoiceLoader()
                ) : (
                <div class="card card-bordered">
                  <div class="card-aside-wrap">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabOrderSummary"
                          >
                            <em class="icon ni ni-user-circle"></em>
                            <span>Order Summary</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getPaymentMethod()}
                            href="#tabPaymentMethod"
                          >
                            <em class="icon ni ni-repeat"></em>
                            <span>Payments</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getPaymentLogs()}
                            href="#tabPaymentLogs"
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Payment Logs</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getOrderComments()}
                            href="#tabOrderComments"
                          >
                            <em class="icon ni ni-bell"></em>
                            <span>Comments</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getOrderDocuments()}
                            href="#tabOrderDocuments"
                          >
                            <em class="icon ni ni-file-docs"></em>
                            <span>Order Documents</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            onClick={() => this.getOrderPaidCommission()}
                            href="#tabOrderRfq"
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Paid Commission Logs</span>
                          </a>
                      </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane active" id="tabOrderSummary">
                          <div
                            class="invoice-wrap"
                            style={{ border: "0px", padding: "2rem" }}
                          >
                            <div class="invoice-head">
                              <div class="invoice-contact">
                                <span class="overline-title">Invoice To</span>
                                <div class="invoice-contact-info">
                                  <h4 class="title">
                                    {this.state.client_company}
                                  </h4>
                                  <ul class="list-plain">
                                    {this.state.client_address &&
                                    this.state.client_address !== "" ? (
                                      <li>
                                        <em className="icon ni ni-map-pin-fill"></em>
                                        <span className="text-dark">
                                          {this.state.client_address &&
                                          this.state.client_address !== ""
                                            ? this.state.client_address
                                            : ""}{" "}
                                          {this.state.client_address2 &&
                                          this.state.client_address2 !== ""
                                            ? this.state.client_address2
                                            : ""}
                                          <br />
                                          {this.state.client_city &&
                                          this.state.client_city !== ""
                                            ? this.state.client_city
                                            : ""}{" "}
                                          {this.state.client_state &&
                                          this.state.client_state !== ""
                                            ? this.state.client_state
                                            : ""}{" "}
                                          {this.state.client_zip &&
                                          this.state.client_zip !== ""
                                            ? this.state.client_zip
                                            : ""}{" "}
                                          {this.state.client_country &&
                                          this.state.client_country !== ""
                                            ? this.state.client_country
                                            : ""}
                                        </span>
                                      </li>
                                    ) : null}
                                    <li>
                                      <em class="icon ni ni-call-fill"></em>
                                      <span className="text-dark">
                                        {this.state.client_phone &&
                                        this.state.client_phone !== ""
                                          ? this.formatPhoneNumber(
                                              this.state.client_phone
                                            )
                                          : "---"}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="invoice-desc right_invoice">
                                <ul class="list-plain">
                                  <li class="invoice-date">
                                    <span>Date</span>:
                                    <span>
                                      {this.state.order_date &&
                                      this.state.order_date !== ""
                                        ? this.format_date(
                                            this.state.order_date
                                          )
                                        : ""}
                                    </span>
                                  </li>
                                  {this.state.dueDate &&
                                  this.state.dueDate !== "" ? (
                                    <li class="invoice-date">
                                      <span
                                        class={
                                          this.state.payment_status &&
                                          this.state.payment_status === "unpaid"
                                            ? "text-danger"
                                            : ""
                                        }
                                      >
                                        Due Date
                                      </span>
                                      :
                                      <span
                                        class={
                                          this.state.payment_status &&
                                          this.state.payment_status === "unpaid"
                                            ? "text-danger"
                                            : ""
                                        }
                                      >
                                        {this.format_date(this.state.dueDate)}
                                      </span>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                            <div class="invoice-bills">
                              <div class="table-responsive">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Item ID</th>
                                      <th>SKU</th>
                                      <th>Description</th>
                                      <th>Qty</th>
                                      <th>Unit</th>
                                      <th>Price</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows.map((row, index) => (
                                      <>
                                        {row.data.type &&
                                        row.data.type === "service" ? (
                                          <tr key={`serv-${index}`}>
                                            <td>
                                              {row.data.id && row.data.id !== ""
                                                ? row.data.id
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.sku &&
                                              row.data.sku !== ""
                                                ? row.data.sku
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.name &&
                                              row.data.name !== ""
                                                ? row.data.name
                                                : "---"}
                                            </td>
                                            <td>{row.data.quantity}</td>
                                            <td>{row.data.unit}</td>
                                            <td>
                                              {this.formatAsCurrency(
                                                Number(row.data.price)
                                              )}
                                            </td>
                                            <td>
                                              {this.formatAsCurrency(
                                                Number(row.data.total)
                                              )}
                                            </td>
                                          </tr>
                                        ) : row.data.type === "section" ? (
                                          <tr key={`serv-${index}`}>
                                            <td
                                              className="text-left"
                                              
                                              colSpan={7}
                                            >
                                              {row.data.name}
                                            </td>
                                          </tr>
                                        ) : row.data.type === "custom" ? (
                                          <tr key={`serv-${index}`}>
                                            <td>{row.data.id}</td>
                                            <td>
                                              {row.data.sku &&
                                              row.data.sku !== ""
                                                ? row.data.sku
                                                : "---"}
                                            </td>
                                            <td>
                                              {row.data.name &&
                                              row.data.name !== ""
                                                ? row.data.name
                                                : "---"}
                                            </td>
                                            <td>{row.data.quantity}</td>
                                            <td>{row.data.unit}</td>
                                            <td>
                                              {this.formatAsCurrency(
                                                Number(row.data.price)
                                              )}
                                            </td>
                                            <td>
                                              {this.formatAsCurrency(
                                                Number(row.data.total)
                                              )}
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td colspan="2">Subtotal</td>
                                      <td>
                                        {this.state.service_grand_sub_total}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td colspan="2">Est. Discount</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(
                                            this.state.discount_value_total
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td colspan="2">Est. Shipping</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(
                                            this.state.shipping_value_total
                                          )
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td colspan="2">Est. TAX</td>
                                      <td>
                                        {this.formatAsCurrency(
                                          parseFloat(this.state.tax_value_total)
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="4"></td>
                                      <td colspan="2">Grand Total</td>
                                      <td>{this.state.service_grand_total}</td>
                                    </tr>
                                  </tfoot>
                                </table>
                                {this.state.quote_notes &&
                                this.state.quote_notes !== "" ? (
                                  <div class="card card-bordered mt-5">
                                    <div class="card-inner">
                                      <div class="nk-help">
                                        <div class="nk-help-text">
                                          <h5>Notes</h5>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: this.state.quote_notes,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tabPaymentMethod">
                          <div className="col-md-12">
                            {this.state.payErrorMessage !== "" ? (
                              <div class="alert alert-pro alert-danger">
                                <div class="alert-text">
                                  <h6>Error</h6>
                                  <p>{this.state.payErrorMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paySuccessMessage !== "" ? (
                              <div class="alert alert-pro alert-success">
                                <div class="alert-text">
                                  <h6>Success</h6>
                                  <p>{this.state.paySuccessMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.paymentMethodLoader === true ? (
                              FormLoader()
                            ) : (
                              <div class="card card-bordered mt-2">
                                <div class="card-inner">
                                  <div class="nk-help">
                                    <div class="nk-help-text">
                                      {this.state.payment_status &&
                                      (this.state.payment_status === "unpaid" ||
                                        this.state.payment_status ===
                                          "partial") ? (
                                        <div className="col-md-12 prettyprint2 mb-4">
                                          <div className="row g-gs">
                                            <div class="col-md-12">
                                              <h5>Add Payment </h5>

                                              <p>
                                                If you received the payment
                                                manually, mark this order as
                                                Full Payment or (Partial Payment
                                                if the amount is less than the
                                                order total or the remaining
                                                amount). Note that this payment
                                                will not be recorded by the
                                                system, and refunds cannot be
                                                processed through the system.
                                                You may also add notes for this
                                                payment if needed.
                                              </p>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label
                                                  class="form-label"
                                                  for="pay_amount"
                                                >
                                                  Amount (USD)
                                                </label>
                                                <div class="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    class="form-control form-control-lg"
                                                    id="pay_amount"
                                                    name="pay_amount"
                                                    onChange={
                                                      this.handleChangeAmount
                                                    }
                                                    value={
                                                      this.state.pay_amount
                                                    }
                                                    onKeyPress={() => {
                                                      this.checkNumber();
                                                    }}
                                                    placeholder=""
                                                    maxLength={14}
                                                    autoComplete="no"
                                                  />
                                                </div>
                                                {this.state
                                                  .amountExceedError !== "" ? (
                                                  <div class="form-note text-danger fw-bold">
                                                    {
                                                      this.state
                                                        .amountExceedError
                                                    }
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>

                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label class="form-label">
                                                  Payment Date
                                                </label>
                                                <div class="form-control-wrap">
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <DateTimePicker
                                                      variant="dialog"
                                                      name="payment_date"
                                                      id="payment_date"
                                                      inputVariant="outlined"
                                                      format="dd/MM/yyyy HH:mm"
                                                      fullWidth
                                                      value={
                                                        this.state.payment_date
                                                      }
                                                      onChange={
                                                        this.handleDateChange
                                                      }
                                                      rientation="landscape"
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                </div>
                                                <div class="form-note">
                                                  Date of payment received.
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label class="form-label">
                                                  Payment Method
                                                </label>
                                                <div class="form-control-wrap">
                                                  <select
                                                    class="form-control form-control-lg"
                                                    name="payment_method_pay"
                                                    id="payment_method_pay"
                                                    onChange={this.handleChange}
                                                  >
                                                    <option
                                                      value="Quickbooks"
                                                      key="aacc-1"
                                                    >
                                                      Quickbooks
                                                    </option>
                                                    <option
                                                      value="bank"
                                                      key="aacc-2"
                                                    >
                                                      Bank Account
                                                    </option>
                                                    <option
                                                      value="wire"
                                                      key="aacc-3"
                                                    >
                                                      Wire Transfer
                                                    </option>
                                                    <option
                                                      value="other"
                                                      key="aacc-33"
                                                    >
                                                      Other
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="form-group">
                                                <label class="form-label">
                                                  Mark Payment As
                                                </label>
                                                <div class="form-control-wrap">
                                                  <select
                                                    class="form-control form-control-lg"
                                                    name="payment_process"
                                                    id="payment_process"
                                                    value={this.state.payment_process}
                                                    onChange={this.handleChange}
                                                  >
                                                    <option
                                                      value="paid"
                                                      key="aacc2-1"
                                                    >
                                                      Full Payment
                                                    </option>
                                                    <option
                                                      value="partial"
                                                      key="aacc2-22"
                                                    >
                                                      Partial Payment
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-12">
                                              <div class="form-group">
                                                <label
                                                  class="form-label"
                                                  for="payment_notes"
                                                >
                                                  Payment Notes
                                                </label>
                                                <div class="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    class="form-control form-control-lg"
                                                    id="payment_notes"
                                                    name="payment_notes"
                                                    onChange={this.handleChange}
                                                    defaultValue={
                                                      this.state.payment_notes
                                                    }
                                                    placeholder="Add payment notes with a maximum of 100 characters.."
                                                    maxLength="100"
                                                    autoComplete="no"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/*<div class="form-group">
                                            <label
                                              class="form-label"
                                              for="payment_receipt"
                                            >
                                              Receipt
                                            </label>
                                            <div class="form-control-wrap">
                                              <div class="custom-file">
                                                <input
                                                  type="file"
                                                  id="payment_receipt"
                                                  name="payment_receipt"
                                                  defaultValue={this.state.payment_receipt}
                                                  class="custom-file-input form-control-lg"
                                                  
                                                  accept=".png, .jpg, .jpeg, .pdf"
                                                  onChange={this.handleFileChange}
                                                />
                                                <label
                                                  class="custom-file-label"
                                                  for="customFile"
                                                >
                                                  Choose receipt (.png, .jpg, .jpeg, .pdf)
                                                </label>
                                              </div>
                                            </div>
                                            </div>*/}
                                            <div className="row g-gs  d-flex justify-content-end">
                                              <div class="nk-block-head-content">
                                                <ul class="nk-block-tools gx-3">
                                                  {this.state.payment_button ===
                                                  true ? (
                                                    <button
                                                      className="btn btn-primary"
                                                      type="button"
                                                      disabled
                                                    >
                                                      <span
                                                        className="spinner-grow spinner-grow-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>
                                                      <span>
                                                        {" "}
                                                        Processing...{" "}
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <li>
                                                      <a
                                                        onClick={() =>
                                                          this.markAsPaid()
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#fff",
                                                        }}
                                                        class="btn btn-success"
                                                      >
                                                        <em class="icon ni ni-check-round-cut"></em>{" "}
                                                        <span>Add Payment</span>{" "}
                                                      </a>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      {/*<h5>Payment Method</h5>
                                      <p className="prettyprint2 mt-2 mb-4">
                                        {this.state.payment_method === "bank"
                                          ? "Bank Account"
                                          : this.state.payment_method === "wire"
                                          ? "Wire Transfer"
                                          : ""}
                                      </p>*/}
                                      {this.state.payment_status &&
                                      this.state.payment_status === "paid" &&
                                      this.state.quick_books_invocie &&
                                      this.state.quick_books_invocie[0] ? (
                                        <>
                                          <h5>QuickBooks Payments</h5>
                                          <div className="col-md-12 prettyprint2">
                                            {paymentDetails ? (
                                              <div style={{ fontSize: "13px" }}>
                                                <p>
                                                  <strong>
                                                    Customer Reference:
                                                  </strong>{" "}
                                                  {paymentDetails.CustomerRef ||
                                                    "N/A"}
                                                </p>
                                                <p>
                                                  <strong>
                                                    Payment Method:{" "}
                                                  </strong>
                                                  {paymentDetails.PaymentMethod ||
                                                    "N/A"}
                                                </p>
                                                <p>
                                                  <strong>
                                                    Payment Reference Number:
                                                  </strong>{" "}
                                                  {paymentDetails.PaymentRefNum ||
                                                    "N/A"}
                                                </p>
                                                <p>
                                                  <strong>
                                                    Total Amount:{" "}
                                                  </strong>
                                                  {this.formatAsCurrency(
                                                    paymentDetails.TotalAmt
                                                  ) || "N/A"}
                                                </p>
                                                <p>
                                                  <strong>
                                                    Transaction Date:{" "}
                                                  </strong>
                                                  {paymentDetails.TxnDate ||
                                                    "N/A"}
                                                </p>
                                                <p>
                                                  <strong>Currency: </strong>
                                                  {paymentDetails.Currency ||
                                                    "N/A"}
                                                </p>
                                              </div>
                                            ) : (
                                              <p style={{ fontSize: "13px" }}>
                                                No payment details available.
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      ) : null}
                                      <h5 className="mt-4">
                                        {this.state.payment_method === "bank"
                                          ? "Payment Details"
                                          : this.state.payment_method === "wire"
                                          ? "Payment Details"
                                          : ""}
                                      </h5>
                                      <p className="prettyprint2 mt-2">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.payment_details,
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabPaymentLogs">
                          <div className="col-md-12">
                            {this.state.paymentLogLoader === true ? (
                              FormLoader()
                            ) : (
                              <div class="card card-bordered mt-2">
                                <div class="card-inner">
                                  <div class="nk-help">
                                    <div class="nk-help-text">
                                      {this.state.payment_logs &&
                                      this.state.payment_logs.length > 0 ? (
                                        <>
                                          <h5>Payment Logs</h5>
                                          <div class="table-responsive">
                                            <table class="table table-striped">
                                              <thead>
                                                <tr>
                                                  <th>Order #</th>
                                                  <th>Method</th>
                                                  <th>Date</th>
                                                  <th>Amount</th>
                                                  <th>Receipt</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.payment_logs.map(
                                                  (logs, index) => (
                                                    <tr key={`log-${index}`}>
                                                      <td>{logs.orderno}</td>
                                                      <td>
                                                        {logs.payment_method ===
                                                        "bank"
                                                          ? "Bank Account"
                                                          : logs.payment_method ===
                                                            "wire"
                                                          ? "Wire Transfer"
                                                          : logs.payment_method ===
                                                            "quickbooks"
                                                          ? "Quickbooks"
                                                          : logs.payment_method}
                                                      </td>
                                                      <td>
                                                        {this.format_date(
                                                          logs.dateCreated
                                                        )}
                                                      </td>
                                                      <td>
                                                        {" "}
                                                        {this.formatAsCurrency(
                                                          parseFloat(
                                                            logs.amount
                                                          )
                                                        )}
                                                      </td>
                                                      <td>
                                                        {logs.payment_receipt &&
                                                        logs.payment_receipt !==
                                                          ""
                                                          ? logs.payment_receipt
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      ) : (
                                        <div class="alert alert-pro alert-warning">
                                          <div class="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Payment logs not found. </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabOrderComments">
                          <div className="col-md-12">
                            {this.state.orderCommentLoader === true ? (
                              FormLoader()
                            ) : (
                              <div class="card card-bordered mt-2">
                                <div class="card-inner">
                                  <div class="nk-help">
                                    <div class="nk-help-text">
                                      {this.state.order_comments &&
                                      this.state.order_comments.length > 0 ? (
                                        <>
                                          {this.state.order_comments.map(
                                            (quote, index) => (
                                              <div
                                                className="nk-reply-item"
                                                key={`keys-${index}`}
                                              >
                                                <div className="nk-reply-header">
                                                  <div className="user-card">
                                                    {quote.from_admin !== "" ? (
                                                      <div className="user-avatar sm bg-blue">
                                                        <span>AD</span>
                                                      </div>
                                                    ) : quote.from_client !==
                                                      "" ? (
                                                      <div className="user-avatar sm bg-pink">
                                                        <span>CL</span>
                                                      </div>
                                                    ) : null}

                                                    <div className="user-name">
                                                      {quote.from_admin !== ""
                                                        ? quote.from_admin
                                                        : quote.from_client !==
                                                          ""
                                                        ? quote.from_client
                                                        : null}
                                                    </div>
                                                  </div>
                                                  <div className="date-time">
                                                    {this.format_date(
                                                      quote.dateAdded
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="nk-reply-body">
                                                  <div className="nk-reply-entry entry">
                                                    <p>Hello,</p>
                                                    <p>{quote.conversation}</p>
                                                    {quote.from_admin !== "" ? (
                                                      <p>
                                                        Thank you <br />{" "}
                                                        {quote.from_admin}
                                                      </p>
                                                    ) : null}
                                                    {quote.from_client !==
                                                    "" ? (
                                                      <p>
                                                        Thank you <br />{" "}
                                                        {quote.from_client}
                                                      </p>
                                                    ) : null}
                                                  </div>
                                                  {quote.quote_file !== "" ? (
                                                    <div className="attach-files invoicefiles">
                                                      <ul className="attach-list">
                                                        <li className="attach-item">
                                                          <a
                                                            className="download"
                                                            href="#"
                                                          >
                                                            <em className="icon ni ni-img"></em>
                                                            <span>
                                                              {quote.quote_file}
                                                            </span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                      <div className="attach-foot">
                                                        <span className="attach-info">
                                                          1 file attached
                                                        </span>
                                                        {this.state
                                                          .download_start ===
                                                        true ? (
                                                          <div
                                                            class="spinner-border spinner-border-sm"
                                                            role="status"
                                                          >
                                                            <span class="sr-only">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <a
                                                            className="attach-download link"
                                                            onClick={() =>
                                                              this.downloadPdf(
                                                                quote.quote_file
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <em className="icon ni ni-download"></em>
                                                            <span>
                                                              Download
                                                            </span>
                                                          </a>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <div class="alert alert-pro alert-warning">
                                          <div class="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Order comments not found. </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="tab-pane" id="tabOrderDocuments">
                          <div className="col-md-12">
                            {this.state.orderDocumentLoader === true ? (
                              FormLoader()
                            ) : (
                              <>
                                <div className="nk-block-head nk-block-head-sm">
                                  <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                      <h5 class="nk-block-title">
                                        <em
                                          class="icon ni ni-file-docs"
                                          style={{ marginRight: "15px" }}
                                        ></em>
                                        Order Documents
                                      </h5>
                                    </div>
                                    <div
                                      className="nk-block-head-content"
                                      id="HeadContentAgent"
                                    >
                                      <div className="toggle-wrap nk-block-tools-toggle">
                                        <a
                                          href="#"
                                          className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                          data-target="pageMenu"
                                        >
                                          <em className="icon ni ni-more-v"></em>
                                        </a>
                                        <div
                                          className="toggle-expand-content"
                                          data-content="pageMenu"
                                        >
                                          <ul className="nk-block-tools g-3">
                                            {this.state.allowed_documents <
                                            6 ? (
                                              <li className="nk-block-tools-opt">
                                                <Link
                                                  color="inherit"
                                                  onClick={() =>
                                                    this.openUploadDocument()
                                                  }
                                                  className="btn btn-primary"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                  }}
                                                >
                                                  <em className="icon ni ni-plus-round"></em>
                                                  &nbsp;Add New Document
                                                </Link>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.orderDocumentErrorMessage !== "" ? (
                                  <div class="alert alert-pro alert-danger mb-2 mt-2">
                                    <div class="alert-text">
                                      <h6>
                                        {this.state.orderDocumentErrorTitle}
                                      </h6>
                                      <p>
                                        {this.state.orderDocumentErrorMessage}{" "}
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.orderDocumentSuccessMessage !==
                                "" ? (
                                  <div class="alert alert-pro alert-success mb-2 mt-2">
                                    <div class="alert-text">
                                      <h6>Success</h6>
                                      <p>
                                        {this.state.orderDocumentSuccessMessage}{" "}
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.orderDocumentForm === true ? (
                                  <div class="card card-bordered mt-2">
                                    <div class="card-inner">
                                      <div class="nk-help">
                                        <div class="nk-help-text">
                                          <div
                                            className="col-md-12 mb-2"
                                            style={{
                                              marginBottom: "20px",
                                              clear: "both",
                                            }}
                                          >
                                            <div className="form-group">
                                              <div class="form-group">
                                                <label
                                                  class="form-label"
                                                  for="document_title"
                                                >
                                                  Title <sup>*</sup>
                                                </label>
                                                <div class="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    class="form-control form-control-lg"
                                                    id="document_title"
                                                    name="document_title"
                                                    onChange={this.handleChange}
                                                    defaultValue={
                                                      this.state.document_title
                                                    }
                                                    placeholder="Add document title with a maximum of 100 characters.."
                                                    maxLength={100}
                                                    autoComplete="no"
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-label-group">
                                                <label className="form-label">
                                                  <Tooltip
                                                    title="Upload order document."
                                                    placement="right"
                                                  >
                                                    <em
                                                      className="icon ni ni-info"
                                                      style={{
                                                        color: "#6576ff",
                                                      }}
                                                    ></em>
                                                  </Tooltip>{" "}
                                                  Upload Document <sup>*</sup>
                                                  <span className="text-danger"></span>
                                                </label>
                                              </div>
                                              <div className="form-control-group signatory_upload mb-4">
                                                <div class="custom-file">
                                                  <input
                                                    type="file"
                                                    class="custom-file-input"
                                                    id="document_attachement"
                                                    name="document_attachement"
                                                    defaultValue={
                                                      this.state
                                                        .document_attachement
                                                    }
                                                    accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .txt, .rtf, .csv, .xlsx, .xls"
                                                    onChange={
                                                      this.handleFileChange
                                                    }
                                                  />
                                                  <label
                                                    class="custom-file-label"
                                                    for="document_attachement"
                                                  >
                                                    Choose file
                                                  </label>
                                                </div>
                                              </div>
                                              <div class="form-note">
                                                {
                                                  this.state
                                                    .document_attachement_name
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12 mt-5">
                                            <div className="example-alert">
                                              <div className="alert alert-light">
                                                <ul className="list">
                                                  <li>
                                                    Accepted Formats: PDF, DOC,
                                                    DOCX, TXT, RTF, PNG, JPEG,
                                                    JPG, CSV, XLS, XLSX, ZIP
                                                  </li>
                                                  <li>Maximum Size: 100MB</li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12 mt-4 mb-2">
                                            <div class="d-flex justify-content-end">
                                              <button
                                                type="button"
                                                class="btn btn-lg btn-light mr-2"
                                                onClick={() =>
                                                  this.setState({
                                                    upload_document_button: false,
                                                    orderDocumentForm: false,
                                                  })
                                                }
                                              >
                                                Cancel
                                              </button>
                                              {this.state
                                                .upload_document_button ===
                                              true ? (
                                                <button
                                                  className="btn btn-lg btn-primary"
                                                  disabled
                                                >
                                                  <em className="icon ni ni-swap"></em>{" "}
                                                  Saving
                                                  <div
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  class="btn btn-lg btn-primary"
                                                  onClick={() =>
                                                    this.submitOrderDocument()
                                                  }
                                                >
                                                  Save Document
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div class="example-alert">
                                  <div class="alert alert-light ">
                                    Please note: A maximum of six documents are
                                    allowed.{" "}
                                  </div>
                                </div>
                                <div class="card card-bordered mt-2">
                                  <div class="card-inner">
                                    <div class="nk-help">
                                      <div class="nk-help-text">
                                        {this.state.order_documents &&
                                        this.state.order_documents.length >
                                          0 ? (
                                          <>
                                            <div class="table-responsive">
                                              <table class="table table-striped">
                                                <thead>
                                                  <tr>
                                                    <th>Uploaded By</th>
                                                    <th>Title</th>
                                                    <th>Date</th>
                                                    <th>Document</th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.order_documents.map(
                                                    (documents, index) => (
                                                      <tr key={`log-${index}`}>
                                                        <td>
                                                          {documents.added_by}
                                                        </td>
                                                        <td>
                                                          {
                                                            documents.document_title
                                                          }
                                                        </td>
                                                        <td>
                                                          {this.format_date(
                                                            documents.dateCreated
                                                          )}
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {this.getFileExtension(
                                                            documents.document_attachement
                                                          )}
                                                        </td>
                                                        <td>
                                                          <a
                                                            onClick={() =>
                                                              this.deleteDocument(
                                                                documents.id
                                                              )
                                                            }
                                                            className="text-danger fs-16px"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <em class="icon ni ni-trash"></em>
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : (
                                          <div class="alert alert-pro alert-warning">
                                            <div class="alert-text">
                                              <h6>No Record</h6>
                                              <p>Documents not found. </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabOrderRfq">
                        {this.state.getOrderPaidCommission === true ? (
                              FormLoader()
                            ) : (
                          <div className="col-md-12">
                            <div class="card card-bordered mt-2">
                              <div class="card-inner">
                                <div class="nk-help">
                                  <div class="nk-help-text">
                                  {this.state.paid_commission_logs &&
                                      this.state.paid_commission_logs.length > 0 ? (
                                        <>
                                          <h5>Paid Commission Logs</h5>
                                          <div class="table-responsive">
                                            <table class="table table-striped">
                                              <thead>
                                                <tr>
                                                  <th>Order #</th>
                                                  <th>Method</th>
                                                  <th>Date</th>
                                                  <th>Amount</th>
                                                  <th>Receipt</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.paid_commission_logs.map(
                                                  (logs, index) => (
                                                    <tr key={`log-${index}`}>
                                                      <td>{logs.orderno}</td>
                                                      <td>
                                                        {logs.payment_method_pay}
                                                      </td>
                                                      <td>
                                                        {this.format_date_time(
                                                          logs.dateAdded
                                                        )}
                                                      </td>
                                                      <td>
                                                        {" "}
                                                        {this.formatAsCurrency(
                                                          parseFloat(
                                                            logs.commission_amount
                                                          )
                                                        )}
                                                      </td>
                                                      <td>
                                                      
                                                        {logs.payment_receipt &&
                                                        logs.payment_receipt !==
                                                          ""
                                                          ? (
                                                            <a
                                                            className="badge badge-primary"
                                                            onClick={() =>
                                                              this.downloadReceipt(
                                                                logs.payment_receipt
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer", color: "#fff"
                                                            }}
                                                          >
                                                            <em className="icon ni ni-download"></em>
                                                            <span>
                                                              Download
                                                            </span>
                                                          </a>
                                                          )
                                                          : "---"}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </>
                                      ) : (
                                        <div class="alert alert-pro alert-warning">
                                          <div class="alert-text">
                                            <h6>Not Found</h6>
                                            <p>Paid commission logs not found. </p>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-info">
                              <div class="badge badge-lg badge-outline-primary badge-pill ucap fw-bold">
                                Order #: {this.state.orderno}
                              </div>
                              <h5>{this.state.client_name}</h5>
                              <span class="sub-text">
                                {this.state.client_email}
                              </span>

                              {this.state.order_status &&
                              this.state.order_status === "cancelled" ? (
                                <div class="badge badge-lg badge-outline-danger badge-pill ucap fw-bold mt-2">
                                  Order Status: {this.state.order_status}
                                </div>
                              ) : this.state.order_status &&
                                this.state.order_status === "completed" ? (
                                <div class="badge badge-lg badge-outline-success badge-pill ucap fw-bold mt-2">
                                  Order Status: {this.state.order_status}
                                </div>
                              ) : null}
                              {this.state.payment_status &&
                              this.state.payment_status === "unpaid" ? (
                                <div class="badge badge-lg badge-outline-danger badge-pill ucap fw-bold mt-2">
                                  Payment Status: {this.state.payment_status}
                                </div>
                              ) : this.state.payment_status &&
                                this.state.payment_status === "paid" ? (
                                <div class="badge badge-lg badge-outline-success badge-pill ucap fw-bold mt-2">
                                  Payment Status: {this.state.payment_status}
                                </div>
                              ) : this.state.payment_status &&
                                this.state.payment_status === "partial" ? (
                                <div class="badge badge-lg badge-outline-warning badge-pill ucap fw-bold mt-2">
                                  Payment Status: Partially Paid
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div class="card-inner card-inner-sm">
                          <ul class="btn-toolbar justify-center gx-1">
                            <li className="mr-2">
                              <Tooltip title="Email" placement="top">
                                <a
                                  onClick={() => this.openEmailWindow()}
                                  class="btn btn-trigger btn-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-mail fs-20px"></em>
                                </a>
                              </Tooltip>
                            </li>
                            <li className="mr-2">
                              <Tooltip
                                title="Download Sale Order PDF"
                                placement="top"
                              >
                                <a
                                  onClick={() =>
                                    this.downloadOrderPdf(
                                      this.state.quote_attachement
                                    )
                                  }
                                  class="btn btn-trigger btn-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-download-cloud fs-20px"></em>
                                </a>
                              </Tooltip>
                            </li>
                            {this.state.order_status === "completed" &&
                            this.state.payment_status === "unpaid" ? (
                              <li>
                                <Tooltip title="Cancel Order" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.rejectSalesOrder()}
                                    class="btn btn-trigger btn-icon text-danger"
                                  >
                                    <em class="icon ni ni-na fs-20px text-danger"></em>
                                  </a>
                                </Tooltip>
                              </li>
                            ) : null}
                            {this.state.order_status &&
                            this.state.order_status !== "completed" ? (
                              <>
                                <li>
                                  <Tooltip title="Edit Order" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.addMenuHistory(
                                          "/EditSalesOrder/" +
                                            this.state.client_accountno +
                                            "/" +
                                            this.state.orderno
                                        )
                                      }
                                      class="btn btn-trigger btn-icon text-danger"
                                    >
                                      <em class="icon ni ni-edit fs-20px"></em>
                                    </a>
                                  </Tooltip>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>

                        {this.state.order_status === "completed" &&
                        this.state.payment_status === "unpaid" &&
                        this.state.invoice_created === "no" ? (
                          <div class="card-inner card-inner-sm">
                            <ul class="btn-toolbar justify-center gx-1">
                              <li className="mr-2">
                                <Tooltip
                                  title="Push to QuickBooks"
                                  placement="top"
                                >
                                  <a
                                    onClick={() => this.openCreateInvoice()}
                                    class="btn btn-trigger btn-icon text-primary"
                                    style={{
                                      cursor: "pointer",
                                      color: "#6576ff",
                                    }}
                                  >
                                    <button
                                      style={{
                                        backgroundColor: "#2ca01c",
                                        color: "white",
                                        border: "none",
                                        padding: "6px 17px",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        display: "flex",
                                        cursor: "pointer",
                                        borderRadius: "2px",
                                        marginLeft: "10px",
                                        outline: "none",
                                      }}
                                    >
                                      <img
                                        src={qbIcon}
                                        alt="QuickBooks Logo"
                                        style={{
                                          height: "25px",
                                          width: "26px",
                                          marginRight: "10px",
                                        }}
                                      />
                                      Push to QuickBooks
                                    </button>
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <>
                            {this.state.quick_books_invocie &&
                              this.state.quick_books_invocie[0] && (
                                <div className="card-inner card-inner-sm">
                                  <ul className="btn-toolbar justify-center gx-1">
                                    <li className="mr-2">
                                      <Tooltip
                                        title={
                                          this.state.quick_books_invocie[0]
                                            .payment_status === "Paid"
                                            ? "View Invoice in QuickBooks"
                                            : "View Invoice in QuickBooks"
                                        }
                                        placement="top"
                                      >
                                        <a
                                          class="btn btn-trigger btn-icon text-success"
                                          onClick={() =>
                                            window.open(
                                              this.state.quick_books_invocie[0]
                                                .payment_status === "Paid"
                                                ? `https://app.qbo.intuit.com/app/invoice?txnId=${this.state.quick_books_invocie[0].invoice_id}`
                                                : this.state
                                                    .quick_books_invocie[0]
                                                    .invoice_link,
                                              "_blank"
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#17a479",
                                          }}
                                        >
                                          <button
                                            style={{
                                              backgroundColor: "#2ca01c",
                                              color: "white",
                                              border: "none",
                                              padding: "6px 9px",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              display: "flex",
                                              cursor: "pointer",
                                              borderRadius: "2px",
                                              marginLeft: "10px",
                                              outline: "none",
                                            }}
                                          >
                                            <img
                                              src={qbIcon}
                                              alt="QuickBooks Logo"
                                              style={{
                                                height: "23px",
                                                width: "24px",
                                                marginRight: "10px",
                                              }}
                                            />
                                            {this.state.quick_books_invocie[0]
                                              .payment_status === "Paid"
                                              ? "View Invoice in QuickBooks"
                                              : "View Invoice in QuickBooks"}
                                          </button>
                                        </a>
                                      </Tooltip>
                                    </li>
                                  </ul>
                                </div>
                              )}
                          </>
                        )}

                        <div class="card-inner">
                          <div class="overline-title-alt mb-2">Total</div>
                          <div class="profile-balance">
                            <div class="profile-balance-group gx-4">
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number">
                                    {this.state.service_grand_total}{" "}
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle fw-bold">
                                  Order Amount
                                </div>
                              </div>
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number text-success">
                                    {this.formatAsCurrency(
                                      this.state.total_paid_amount
                                    )}{" "}
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle text-success fw-bold">
                                  Paid Amount
                                </div>
                              </div>
                            </div>
                            <div class="profile-balance-group gx-4 mt-4">
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number text-danger">
                                    {this.formatAsCurrency(
                                      this.state.total_remaining_amount
                                    )}{" "}
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle text-danger fw-bold">
                                  Remaining Amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">
                            Commission Details
                          </h6>
                          <p>
                            <span style={{ fontWeight: "600" }}>
                              Channel Partner:
                            </span>{" "}
                            {this.state.agent_name} (
                            {this.state.agent_comp_name})
                          </p>
                          <p>
                            <span style={{ fontWeight: "600" }}>
                              Commission :{" "}
                            </span>
                            {this.state.commission_value_type ===
                            "percentage" ? (
                              <>
                                {this.state.commission_value}% of the order
                                total
                              </>
                            ) : (
                              <>
                                ${this.state.commission_value} fixed commission
                              </>
                            )}
                          </p>
                          <div class="row text-center">
                            <div class="col-6 text-left">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.formatAsCurrency(
                                    Number(this.state.total_commission)
                                  )}
                                </span>
                                <span class="sub-text"> Commission</span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="profile-stats mt-1">
                                {this.state.commission_approved === 1 ? (
                                  <button
                                  type="button"
                                  className="btn btn-sm btn-outline-success"
                                  style={{
                                    
                                    display: "block",
                                    
                                  }}
                                >
                                  <em class="icon ni ni-done"></em>
                                  <span>Approved</span>
                                </button>
                                ) : (
                                <a
                                  onClick={() => this.approveCommission()}
                                  className="btn btn-sm btn-primary"
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                    color: "white",
                                  }}
                                >
                                  <em class="icon ni ni-done"></em>{" "}
                                  <span>Approve</span>
                                </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">From:</span>
                              <span>{this.state.reseller_company}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Payment terms:</span>
                              <span>{this.state.payment_terms}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Currency:</span>
                              <span>{this.state.currency}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Contact Person:</span>
                              <span>{this.state.contact_person}</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Validity:</span>
                              <span>{this.state.validity} Days</span>
                            </div>
                            {this.state.quote_accountno &&
                            this.state.quote_accountno !== "" ? (
                              <div class="col-6">
                                <span class="sub-text">Quotation:</span>
                                <a
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/EditQuotations/" +
                                        this.state.client_accountno +
                                        "/" +
                                        this.state.quote_accountno
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>
                                    <span class="badge badge-outline-primary">
                                      <em class="icon ni ni-card-view"></em>{" "}
                                      View
                                    </span>
                                  </span>
                                </a>
                              </div>
                            ) : null}
                            {this.state.rfq_accountno &&
                            this.state.rfq_accountno !== "" ? (
                              <div class="col-6">
                                <span class="sub-text">RFQ:</span>
                                <a
                                  onClick={() => this.showRequirements()}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>
                                    <span class="badge badge-outline-primary">
                                      <em class="icon ni ni-card-view"></em>{" "}
                                      View
                                    </span>
                                  </span>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                          )}







              </div>

              {/* Table 5th Col End */}
              {/* Email Modal */}
              <div class="modal fade" tabindex="-1" id="modalFormEmail">
                <div
                  class="modal-dialog modal-dialog-top modal-xl "
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        <em class="icon ni ni-emails"></em> Send Invoice Email
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div class="row g-gs">
                        <div class="col-sm-12">
                          {this.state.emailErrorMessage !== "" ? (
                            <div class="alert alert-pro alert-danger">
                              <div class="alert-text">
                                <h6>{this.state.emailErrorMessageTitle}</h6>
                                <p>{this.state.emailErrorMessage}</p>
                              </div>
                            </div>
                          ) : null}
                          {this.state.emailSuccessMessage !== "" ? (
                            <div class="alert alert-pro alert-success">
                              <div class="alert-text">
                                <h6>Success</h6>
                                <p>{this.state.emailSuccessMessage}</p>
                              </div>
                            </div>
                          ) : null}
                          <div class="form-group">
                            <label class="form-label" for="emailto">
                              To <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailto"
                                name="emailto"
                                value={this.state.emailto}
                                onChange={this.handleChange}
                                placeholder="Enter email address"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label class="form-label" for="emailcc">
                              Cc
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailcc"
                                name="emailcc"
                                value={this.state.emailcc}
                                onChange={this.handleChange}
                                placeholder="Enter CC email addresses separated by commas"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label class="form-label" for="emailbcc">
                              Bcc
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailbcc"
                                name="emailbcc"
                                value={this.state.emailbcc}
                                onChange={this.handleChange}
                                placeholder="Enter Bcc email addresses separated by commas"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mt-2">
                          <div class="form-group">
                            <label class="form-label" for="emailsubject">
                              Subject <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                id="emailsubject"
                                name="emailsubject"
                                value={this.state.emailsubject}
                                onChange={this.handleChange}
                                placeholder="Email Subject"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mt-2">
                          <div class="form-group">
                            <label class="form-label" for="emailcontent">
                              Custom Message <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <textarea
                                class="form-control"
                                id="emailcontent"
                                name="emailcontent"
                                value={this.state.emailcontent}
                                onChange={this.handleChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 mt-2 d-flex justify-content-end">
                          <div class="nk-block-head-content">
                            <ul class="nk-block-tools gx-3">
                              {this.state.email_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Processing... </span>
                                </button>
                              ) : (
                                <li>
                                  <a
                                    onClick={() => this.submitOrderEmail()}
                                    style={{
                                      cursor: "pointer",
                                      color: "#fff",
                                    }}
                                    class="btn btn-primary"
                                  >
                                    <em class="icon ni ni-check-round-cut"></em>{" "}
                                    <span>Send Email</span>{" "}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Email Modal */}
              {/* RFQ MODAL */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalFormOptions"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> RFQ
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.rfq_details &&
                                this.state.rfq_details[0].rfq_questions.length >
                                  0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        General Assesment
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th className="w-150px">
                                              Question
                                            </th>
                                            <th className="w-60">Answer</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.rfq_details[0].rfq_questions.map(
                                            (rfq, index) => (
                                              <tr key={index}>
                                                <td>{rfq.question}</td>
                                                <td>{rfq.answer}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.quotation_services &&
                                this.state.quotation_services.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Products & Services
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      {this.state.rfq_details &&
                                      this.state.rfq_details.length > 0 ? (
                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <th className="w-60">
                                                Product Name
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.quotation_services.map(
                                              (service, sid) => (
                                                <tr key={`ser${sid}`}>
                                                  <td>
                                                    {service.service_name}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.rfq_attachment &&
                                this.state.rfq_attachment !== "" ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">Attachement</h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-5">
                                          <p>
                                            <a
                                              onClick={() =>
                                                this.downloadPdfRfq(
                                                  this.state.rfq_attachment
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                              title="Download Attachment"
                                            >
                                              <span className="badge badge-outline-secondary">
                                                {" "}
                                                <em className="icon ni ni-download"></em>{" "}
                                                Download Attachment
                                              </span>
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card-inner">
                                  <div className="sp-plan-head-group">
                                    <div className="sp-plan-head">
                                      <h6 className="title">Requirement</h6>
                                      <span className="ff-italic text-soft">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.rfq_requirements,
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* RFQ Modal */}
              {/****** Rejection Modal *****/}
              <div class="modal fade" tabindex="-1" id="modalRejectSalesOrder">
                <div
                  class="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">
                        <em class="icon ni ni-cross-round-fill"></em> Reject
                        Quotation
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div className="row g-gs">
                        {this.state.rejectErrorMessage !== "" ? (
                          <div class="col-sm-12 mb-4">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>{this.state.rejectErrorMessageTitle}</h4>
                                  <p>{this.state.rejectErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.rejectSuccessMessage !== "" ? (
                          <div class="col-sm-12 mb-4">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.rejectSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div class="col-sm-12 mb-4">
                          <div class="form-group">
                            <label class="form-label" for="default-textarea">
                              Reason <sup>*</sup>
                            </label>
                            <div class="form-control-wrap">
                              <textarea
                                class="form-control"
                                id="rejection_reason"
                                name="rejection_reason"
                                defaultValue={this.state.rejection_reason}
                                onChange={this.handleChange}
                                placeholder="Please provide the reason for rejecting the order in this space."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-gs d-flex justify-content-end mt-5 border-top">
                        <div className="" style={{ float: "right" }}>
                          {this.state.rejectionButton === true ? (
                            <button
                              className="btn btn-primary btn-lg"
                              type="button"
                              id="loading_btn"
                              style={{
                                margin: "13px 12px 12px 10px",
                              }}
                              disabled
                            >
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Processing... </span>
                            </button>
                          ) : this.state.rejectionButton === false ? (
                            <button
                              className="btn btn-primary btn-lg"
                              style={{
                                margin: "13px 12px 12px 10px",
                              }}
                              onClick={() => this.rejectQuotationSubmit()}
                              id=""
                            >
                              <em class="icon ni ni-cross-round-fill"></em>{" "}
                              <span>Reject</span>{" "}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/****** Rejection Modal *****/}

              {/* Link Modal MODAL START */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalMarketingPDFLink"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Order
                          Document
                        </strong>
                      </div>
                      <a
                        className="close"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.modalHideMarketingPDF();
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      <br />
                      <embed
                        src={this.state.currentDocumentLink}
                        type={this.state.currentDocumentType}
                        width="100%"
                        height={newHeight * 0.61}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* LINK MODAL End */}
              {/* CREATE INVOICE */}
              <div class="modal fade" tabindex="-1" id="modalCreateInvoice">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideRemove("modalCreateInvoice");
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "2rem",
                          }}
                        >
                          <div
                            style={{
                              width: "90px",
                              height: "90px",
                              backgroundColor: "#f0f0f0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              src={LOGO}
                              alt="Comp-Logo"
                              style={{
                                width: "65px",
                              }}
                            />
                          </div>
                          <span
                            style={{
                              fontSize: "24px",
                              margin: "0 10px",
                              color: "#2CA01C",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            • • •
                          </span>
                          <img
                            src={qbImage}
                            alt="QuickBooks"
                            style={{ width: "80px" }}
                          />
                        </div>

                        <h4 className="nk-modal-title">Push Invoice?</h4>
                        <div className="nk-modal-text">
                          {this.state.invoiceErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h5>{this.state.invoiceErrorMessageTitle}</h5>
                                  <p>{this.state.invoiceErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.invoiceAuthErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-warning">
                                <div className="alert-text">
                                  <h5>
                                    {this.state.invoiceAuthErrorMessageTitle}
                                  </h5>
                                  <p>{this.state.invoiceAuthErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.invoiceSuccessMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.invoiceSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="caption-text">
                            Are you sure you want to push the invoice in Quick
                            Books?
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          {this.state.invoice_button === true ? (
                            <button
                              class="btn btn-lg btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span> Pushing... </span>
                            </button>
                          ) : (
                            <>
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-secondary"
                                onClick={() => {
                                  this.modalHideRemove("modalCreateInvoice");
                                }}
                                disabled={this.state.disabled}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#2CA01C",
                                  borderColor: "#2CA01C",
                                }}
                                className="btn btn-lg btn-mw"
                                onClick={() => {
                                  this.create_external_invoice();
                                }}
                                disabled={this.state.disabled}
                              >
                                PUSH INVOICE
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* CREATE INVOICE */}

              {/* Alert INVOICE */}
              <div class="modal fade" tabindex="-1" id="modalAlertInvoice">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        {/* <em
                          class="icon ni ni-alert-circle"
                          style={{ fontSize: "3rem" }}
                        ></em> */}
                        <img
                          src={qbImage}
                          alt="QuickBooks"
                          style={{ width: "80px" }}
                        />
                        <h4 className="nk-modal-title">
                          QuickBooks Not Connected
                        </h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            Please establish a successful connection with
                            QuickBooks before pushing an invoice.
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-secondary"
                            onClick={() => {
                              this.modalHideRemoveAlert("modalAlertInvoice");
                            }}
                            disabled={this.state.disabled}
                          >
                            CLOSE
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            href="/Integrations"
                            disabled={this.state.disabled}
                          >
                            CREATE CONNECTION
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Alert INVOICE */}
              {/* COMMISSION APPROVAL MODAL */}
              <div class="modal fade" tabindex="-1" id="approveCommisionModal">
                <div
                  class="modal-dialog modal-lg modal-dialog-top"
                  role="document"
                >
                  <div class="modal-content">
                    <a
                      href="#"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-header">
                      <h5 class="modal-title">Approve Commission</h5>
                    </div>
                    <div class="modal-body">
                      <div class="row g-gs">
                        
                          {this.state.commissionError !== "" ? (
                            <div class="col-sm-12">
                            <div class="alert alert-pro alert-danger">
                              <div class="alert-text">
                                <h6>{this.state.commissionErrorTitle}</h6>
                                <p>{this.state.commissionError}</p>
                              </div>
                            </div>
                            </div>
                          ) : null}
                          {this.state.commissionSuccessMessage !== "" ? (
                            <div class="col-sm-12">
                            <div class="alert alert-pro alert-success">
                              <div class="alert-text">
                                <h6>Success</h6>
                                <p>{this.state.commissionSuccessMessage}</p>
                              </div>
                            </div>
                            </div>
                          ) : null}
                       

                        {this.state.allow_commission_approval === true ? (
                          <>
                            <div className="col-sm-6">
                              <div class="form-group">
                                <label
                                  class="form-label"
                                  for="total_commission_pay"
                                >
                                  Commission Amount <sup>*</sup>
                                </label>
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    id="total_commission_pay"
                                    name="total_commission_pay"
                                    value={this.state.total_commission_pay}
                                    onChange={this.handleChangeCommission}
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 mt-4 d-flex justify-content-start">
                              <div class="nk-block-head-content mt-1">
                                <ul class="nk-block-tools gx-3">
                                  {this.state.commission_pay_button === true ? (
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span> Processing... </span>
                                    </button>
                                  ) : (
                                    <li>
                                      <a
                                        onClick={() => this.approveCommissionSubmit()}
                                        style={{
                                          cursor: "pointer",
                                          color: "#fff",
                                        }}
                                        class="btn btn-primary"
                                      >
                                        <em class="icon ni ni-check-round-cut"></em>{" "}
                                        <span>Approve</span>{" "}
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* COMMISSION APPROVAL MODAL */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUotations);
